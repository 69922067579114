/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets-new/images/logo (1).svg";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";

const NavPopup = ({ clickFunc, showPopup }) => {
  useEffect(() => {
    const body = document.body;
    body.className = showPopup ? "show-overlay-nav" : "";
  }, [showPopup]);
  return (
    <>
      <div
        className={`popup popup__menu ${showPopup ? "viewPopup" : "closePopup"
          }`}
      >
        <a
          href="/"
          id="close-popup"
          className="close-popup"
          rel="noreferrer"
          onClick={(e) => {
            clickFunc();
            e.preventDefault();
          }}
        ></a>
        <div className="container mobileContainer">
          <div className="row">
            <div className="col-lg-12 text-left">
              <div className="logo2">
                <Link to="/" onClick={clickFunc}>
                  <img src={Logo} alt="logo" />
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="popup-inner">
                <div className="dl-menu__wrap dl-menuwrapper">
                  <ul className="dl-menu dl-menuopen">
                    <li>
                      <Link to="/about-us" onClick={clickFunc}>
                        about us
                      </Link>
                    </li>
                    <li>
                      <Link to="/video-services" onClick={clickFunc}>
                        services
                      </Link>
                    </li>
                    <li>
                      <Link to="portfolio" onClick={clickFunc}>
                        Video Categories
                      </Link>
                    </li>
                    <li className="d-inline-flex align-items-center">
                      <Link to="/careers" onClick={clickFunc}>
                        <span>careers </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/insights" onClick={clickFunc}>
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="tel:9867409221"
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        +91 9867409221
                      </Link>
                    </li>
                    <li
                      style={{
                        paddingRight: 2,
                      }}
                      onClick={clickFunc}
                    >
                      <Link to="/contact" className="contact-btn">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-6 col-xs-12 text-left">
              <ul className="footer__contacts text-left">
                <li>
                  Phone:{" "}
                  <a style={{ color: "inherit" }} href="tel:+919867409221">
                    +91 98674 09221
                  </a>
                </li>
                <li>
                  Email:{" "}
                  <a
                    style={{ color: "inherit" }}
                    href="mailto:sales@orangevideos.in"
                  >
                    sales@orangevideos.in
                  </a>
                </li>
                {/* <!--<li>Address: C-208, Mittal Estate, Andheri-Kurla Road, Andheri (E), Mumbai 400059</li>--> */}
                <li>
                  Orange Videos Production LLP
                  <br />
                  401-A, Town Center 2, Andheri Kurla Road,
                  <br />
                  Andheri East, Mumbai 400059
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-sm-6 col-xs-12 col-xs-12">
              <div className="popUp_social text-right">
                <ul>
                  <li>
                    <a
                      href="https://www.instagram.com/orange_videos/?next=%2F"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaInstagram /> Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/orangevideosofficial"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaFacebook /> Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/Orange_Videos"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaXTwitter /> Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCTfxtQyIJyvlpwKrvLsRj7w/videos"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaYoutube /> YouTube
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/13413575/admin/feed/posts/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaLinkedinIn /> LinkedIn
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavPopup;

import React from "react";
// import { FaPlay } from "react-icons/fa6";
// import CoinDCX from "../../assets-new/videos/bts/coinDCX.mp4";
// import hapagLloyd from "../../assets-new/videos/bts/hapag-lloyd-optimized.mp4";
// import bnk from "../../assets-new/videos/bts/bnk.mp4";
// import bnk2 from "../../assets-new/videos/bts/bnk-v1.mp4";
// import mentco from "../../assets-new/videos/bts/mentco.mp4";
// import zeroB from "../../assets-new/videos/bts/zeroB.mp4";
// import greenScreen from "../../assets-new/videos/bts/greenScreen.mp4";
// import industrialShootKelvion from "../../assets-new/videos/bts/industrial-shoot-Kelvion.mp4";
// import { FaPause } from "react-icons/fa";
// import { useInView } from "framer-motion";
// import BtsVideoContainer from "./BtsVideoContainer";
import coindcx from "../../assets-new/videos/bts/compressed/for-ios/Coindcx.mp4";
import bnk from "../../assets-new/videos/bts/compressed/for-ios/Bnk.mp4";
import mentco from "../../assets-new/videos/bts/compressed/for-ios/Mentco.mp4";
import zeroB from "../../assets-new/videos/bts/compressed/for-ios/Zerob.mp4";
import greenScreen from "../../assets-new/videos/bts/compressed/for-ios/Greenscreen.mp4";
import HapagLloyd from "../../assets-new/videos/bts/compressed/for-ios/Hapag-Lloyd-Optimized.mp4";
import { sanitize } from "dompurify";
import { isMacOs } from "react-device-detect";
import LazyLoad from "react-lazyload";

// const BtsVideoContainer = lazy(() => import("./BtsVideoContainer"));

const BTSVideos = ({ show = false }) => {
  // const dcxRef = useRef(null);

  // const [isPlaying, setIsPlaying] = useState(false);

  // const playVideo = () => {
  //   console.log(dcxRef);
  //   setIsPlaying(!isPlaying);

  //   if (dcxRef.current.autoplay) {
  //     dcxRef.current.currentTime = 0;
  //     dcxRef.current.play();
  //     dcxRef.current.autoplay = false;
  //     dcxRef.current.loop = false;
  //   } else {
  //     if (isPlaying) {
  //       dcxRef.current.pause();
  //     } else {
  //       dcxRef.current.play();
  //     }
  //   }
  // };

  // const vid1Ref = useRef();

  // const videoSectionRef = useRef(null);

  // const [isVisible, setIsVisible] = useState(false);

  // const variants = {
  //   hidden: { opacity: 0 },
  //   visible: { opacity: 1 },
  // };

  // useInView(videoSectionRef, { once: false }, (entry) =>
  //   setIsVisible(entry.isIntersecting)
  // );

  const mobileMediaQuery = window.matchMedia("(max-width: 500px)");

  const videoConfig = {
    ALLOWED_TAGS: ["video", "source"],
    ALLOWED_ATTRS: {
      video: ["autoplay", "loop", "muted", "poster"],
      source: ["src", "type"],
    },
  };

  return (
    <div
      className="commonSection bts-videos-section"
      // style={{
      //   display: show ? "block" : "none",
      // }}
    >
      <div className="container">
        <h2
          className="sec_title text-center margin-under-heading"
          style={{
            marginBottom: 20,
          }}
        >
          BTS
        </h2>
        <p className="psub_heading text-center">
          Raw, unedited footage revealing our secrets of film creations
        </p>
        <div
          className="bts-videos-container"
          // ref={videoSectionRef}
          // variants={variants}
          // animate="visible"
          // exit="hidden"
          // initial="hidden"
          // whileInView={{
          //   once: false,
          // }}
        >
          <div className="bts-video-content">
            {/* <BtsVideoContainer videoSrc={coindcx} /> */}
            <LazyLoad height={200} offset={1000}>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(`
                  <video
                  width="100%"
                  autoPlay
                  loop
                  muted
                  playsinline
                  preload="metadata"
                  >
                  <source src=${
                    mobileMediaQuery.matches || isMacOs
                      ? "https://orangevideos.vercel.app/static/media/Coindcx.e15eb6cb9a5a06ed1854.mp4"
                      : coindcx
                  } type="video/mp4" />
                  </video>
                  `),
                  videoConfig,
                }}
              ></div>
            </LazyLoad>
            <div className="hover-video-content">
              <h5>Coin DCX </h5>
            </div>
          </div>
          <div className="bts-video-content">
            <LazyLoad height={200} offset={1000}>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(`
            <video
              width="100%"
              autoPlay
              loop
              muted
              playsinline
            >
              <source src=${
                mobileMediaQuery.matches || isMacOs
                  ? "https://orangevideos.vercel.app/static/media/Bnk.6ed17b5818ddcb613b1a.mp4"
                  : bnk
              } type="video/mp4" />
            </video>
            `),
                  videoConfig,
                }}
              ></div>
            </LazyLoad>
            {/* <BtsVideoContainer videoSrc={bnk} isEleVideo={true} /> */}
            <div className="hover-video-content">
              <h5>LINK BNK Launch Party</h5>
            </div>
          </div>
          <div className="bts-video-content">
            {/* <BtsVideoContainer videoSrc={mentco} isEleVideo={true} /> */}
            <LazyLoad height={200} offset={1000}>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(`
            <video
            width="100%"
            autoPlay
            loop
            muted
              playsinline
              >
              <source src=${
                mobileMediaQuery.matches || isMacOs
                  ? "https://orangevideos.vercel.app/static/media/Mentco.bb5e281e0aed391c0815.mp4"
                  : mentco
              } type="video/mp4" />
                </video>
                `),
                  videoConfig,
                }}
              ></div>
            </LazyLoad>
            <div className="hover-video-content">
              <h5>Mentco</h5>
            </div>
          </div>
          <div className="bts-video-content">
            {/* <BtsVideoContainer videoSrc={zeroB} /> */}
            <LazyLoad height={200} offset={1000}>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(`
                  <video
                  width="100%"
                  autoPlay
              loop
              muted
              playsinline
            >
              <source src=${
                mobileMediaQuery.matches || isMacOs
                  ? "https://orangevideos.vercel.app/static/media/Zerob.9a8b98b567ce3179d8dd.mp4"
                  : zeroB
              } type="video/mp4" />
                </video>
                `),
                  videoConfig,
                }}
              ></div>
            </LazyLoad>
            <div className="hover-video-content">
              <h5>Zero B</h5>
            </div>
          </div>
          <div className="bts-video-content">
            {/* <BtsVideoContainer videoSrc={greenScreen} /> */}
            <LazyLoad height={200} offset={1000}>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(`
            <video
              width="100%"
              autoPlay
              loop
              muted
              playsinline
            >
              <source src=${
                mobileMediaQuery.matches || isMacOs
                  ? "https://orangevideos.vercel.app/static/media/Greenscreen.d6f3cdabbbd42002ad15.mp4"
                  : greenScreen
              } type="video/mp4" />
                </video>
                `),
                  videoConfig,
                }}
              ></div>
            </LazyLoad>
            <div className="hover-video-content">
              <h5>All About Marketing</h5>
            </div>
          </div>
          <div className="bts-video-content">
            {/* <BtsVideoContainer videoSrc={HapagLloyd} isEleVideo={true} /> */}
            <LazyLoad height={200} offset={1000}>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(`
            <video
            width="100%"
              autoPlay
              loop
              muted
              playsinline
            >
              <source src=${
                mobileMediaQuery.matches || isMacOs
                  ? "https://orangevideos.vercel.app/static/media/Hapag-Lloyd-Optimized.6e1f0027374e945afd6a.mp4"
                  : HapagLloyd
              } type="video/mp4" />
                </video>
                `),
                  videoConfig,
                }}
              ></div>
            </LazyLoad>
            <div className="hover-video-content">
              {/* <h5>Hapag Lloyd</h5> */}
              <h5>Morgan Stanley</h5>
            </div>
          </div>
          {/* <div className="bts-video-content">
            <BtsVideoContainer videoSrc={industrialShootKelvion} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default BTSVideos;

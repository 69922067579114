import React from "react";
import { Helmet } from "react-helmet-async";

const MetaData = ({
  pageTitle,
  metaDdescription,
  elseContent = false,
  isContactPage = false,
}) => {
  return (
    <Helmet>
      <title>{pageTitle && pageTitle}</title>
      <meta name="description" content={metaDdescription}></meta>
      {elseContent &&
        `
        <!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Q1BN90WK19"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Q1BN90WK19');

gtag('config', 'AW-11344569007');
</script>
        `}
    </Helmet>
  );
};

export default MetaData;

import { Link } from "react-router-dom";
import HomeSection7 from "./HomeSection7";
import { motion } from "framer-motion";
import BannerVideo from "../../assets-new/videos/ov_banner_compressed_new.mp4";
import Section2 from "./Section2";
import Section3 from "./Section3";
import "../styles/Home.css";
import Section5 from "./Section5";
import { AppUseContext } from "../AppContext";
import { useEffect } from "react";
import ClientTestimonialSection from "./ClientTestimonialSection";
import FollowUsSection from "./FollowUsSection";
import Section4 from "./Section4";
import BTSVideos from "./BTSVideos";
import WhyUsSection from "./WhyUsSection";
import videoPoster from "../../assets-new/images/homepage-banner-video-poster.jpg";
import { sanitize } from "dompurify";
import { isIOS, isMacOs } from "react-device-detect";

const Home = () => {
  const { setIsOnHomepage } = AppUseContext();

  useEffect(() => {
    setIsOnHomepage(true);
    // eslint-disable-next-line
  }, []);

  const txtAnimation = {
    show: { transform: "scale(1)", opacity: 1 },
    hide: { transform: "scale(0)", opacity: 0 },
    // show: { top: 0, opacity: 1 },
    // hide: { top: "-50", opacity: 0 },
  };

  const span2 = {
    open: { opacity: 1 },
    close: { opacity: 0 },
  };

  const videoConfig = {
    ALLOWED_TAGS: ["video", "source"],
    ALLOWED_ATTRS: {
      video: ["autoplay", "loop", "muted", "poster"],
      source: ["src", "type"],
    },
  };

  const mobileMediaQuery = window.matchMedia("(max-width: 500px)");
  return (
    <>
      {/* section 1 */}
      <section
        style={{
          minHeight: "100vh",
          height: "100%",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#000",
        }}
        className="mobile-section video-head-section"
      >
        <div className="container-fluid container-fluid-custom">
          <div className="overlay-bg"></div>
          <span
            dangerouslySetInnerHTML={{
              __html: sanitize(`
            <video
              playsinline
              id="myVideo"
              preload="auto"
              poster=${
                isIOS || isMacOs
                  ? "https://orangevideos.vercel.app/static/media/ov_banner_compressed_new.87ada5d18dfab49fa4dd.mp4"
                  : videoPoster
              }
              muted
              autoPlay
              loop
              src=${BannerVideo}
              width="100%"
              >
              <source src=${BannerVideo} type="video/mp4" />
              </video>
              `),
              videoConfig,
            }}
          ></span>
          {/* <video
            playsinline
            id="myVideo"
            preload="auto"
            poster={videoPoster}
            muted
            autoPlay
            loop
            src={BannerVideo}
            width="100%"
          /> */}
          {/* <source src={BannerVideo} type="video/mp4" />
          </video> */}
          <div className="container custom-text">
            <div
            // initial="hide"
            // whileInView="view"
            // viewport={{ once: true }}
            // variants={{
            //   view: { opacity: 1, y: 0 },
            //   hide: { opacity: 0, y: 100 },
            // }}
            // transition={{ duration: 1 }}
            >
              <div>
                <div
                  className="heading-section-right banner_content home-heading-text"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <h4>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        gap: 5,
                      }}
                    >
                      {["Lights ", "Camera ", "story"].map((text, ind) => {
                        // console.log(ind * 0.1);
                        return (
                          <motion.span
                            key={ind}
                            variants={txtAnimation}
                            animate="show"
                            exit="hide"
                            initial="hide"
                            transition={{ duration: 1, delay: ind * 0.1 }}
                            style={{
                              position: "relative",
                              display: "block",
                            }}
                            whileInView={{
                              once: true,
                            }}
                          >
                            {text}
                          </motion.span>
                        );
                      })}
                    </span>
                    <motion.span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        gap: 5,
                      }}
                      variants={span2}
                      initial="close"
                      animate="open"
                      transition={{ delay: 1.2 }}
                    >
                      {["CRAFTING ", "CINEMATIC ", "MAGIC:"].map(
                        (text, ind) => {
                          // console.log(ind * 0.1);
                          return (
                            <motion.span
                              key={ind}
                              variants={txtAnimation}
                              animate="show"
                              exit="hide"
                              initial="hide"
                              transition={{ duration: 1, delay: ind * 0.6 }}
                              style={{
                                position: "relative",
                                display: "block",
                              }}
                              whileInView={{
                                once: true,
                              }}
                            >
                              {text}
                            </motion.span>
                          );
                        }
                      )}
                    </motion.span>
                    {/* {[
                      "L",
                      "i",
                      "g",
                      "h",
                      "t",
                      "s, ",
                      "C",
                      "a",
                      "m",
                      "e",
                      "r",
                      "a, ",
                      "s",
                      "t",
                      "o",
                      "r",
                      "y:",
                      <br />,
                      "C",
                      "r",
                      "a",
                      "f",
                      "t",
                      "i",
                      "n",
                      "g ",
                      "c",
                      "i",
                      "n",
                      "e",
                      "m",
                      "a",
                      "t",
                      "i",
                      "c ",
                      "M",
                      "a",
                      "g",
                      "i",
                      "c",
                    ].map((text, ind) => {
                      // console.log(ind * 0.1);
                      return (
                        <motion.span
                          key={ind}
                          variants={txtAnimation}
                          animate="show"
                          exit="hide"
                          initial="hide"
                          transition={{ duration: 1, delay: ind * 0.1 }}
                          style={{
                            position: "relative",
                          }}
                          whileInView={{
                            once: true,
                          }}
                        >
                          {text}
                        </motion.span>
                      );
                    })} */}
                    {/* Lights, Camera, Story: */}
                    {/* <br /> */}
                    {/* Crafting Cinematic Magic */}
                  </h4>
                  <h1 style={{ color: "#ff950d" }}>
                    CREATIVE VIDEO PRODUCTION WITH
                    <br />
                    STRATEGIC MARKETING THINKING
                  </h1>
                  {/* <h2 style={{ color: "#ff950d" }}>
                    Welcome to <br /> Orange Videos
                  </h2> */}
                  <p
                    style={{
                      color: "#fff",
                      marginTop: 20,
                      lineHeight: 1.5,
                      marginBottom: 30,
                    }}
                  >
                    We’re a video production agency helping businesses and
                    brands turn their vision{" "}
                    {!mobileMediaQuery.matches && <br />} into reality through
                    dynamic video content.
                  </p>
                  <Link
                    to="/contact"
                    className="guide-btn"
                    // className="common_btn red_bg"
                    //
                  >
                    <span>Enquire Now</span>
                  </Link>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <svg
          className="header-separator"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="100%"
          height="30"
          viewBox="0 0 1920 90"
          preserveAspectRatio="none"
        >
          <path
            fill="#f4f4f4"
            d="M0,0c703,0,1799.426,155.567,1920,0v90H0V0z"
          ></path>
        </svg> */}
      </section>
      {/* <HeadingTestSection /> */}

      {/* test section 2 */}
      <Section2 />

      {/* test section 3 */}
      <Section3 />

      <div className="stats-section">
        <div className="container commonSection">
          <div className="row">
            <div className="col-md-2 col-sm-12 stats-content">
              <h3 className="stats-heading">12+</h3>
              <span>years experience</span>
            </div>
            <div className="col-md-2 col-sm-12 stats-content">
              <h3 className="stats-heading">750+</h3>
              <span>Videos</span>
            </div>
            <div className="col-md-2 col-sm-12 stats-content">
              <h3 className="stats-heading">500+</h3>
              <span>Happy clients </span>
            </div>
            <div className="col-md-2 col-sm-12 stats-content">
              <h3 className="stats-heading">70+</h3>
              <span>Creative Minds </span>
            </div>
            <div className="col-md-2 col-sm-12 stats-content">
              <h3 className="stats-heading">12</h3>
              <span>Countries & counting</span>
            </div>
          </div>
        </div>
      </div>

      {/* WORK SHOWCASE section component */}
      <Section5 />

      {/* section 4 */}
      <Section4 />

      <HomeSection7 />

      {/* test section 6 */}
      <ClientTestimonialSection />
      {/* <HomeSection6 /> */}

      {/* section 7 */}
      {/* <HomeSection7 /> */}

      {/* BTS videos section */}
      <BTSVideos />

      {/* why us section */}
      <WhyUsSection />

      {/* test section 7 */}
      <FollowUsSection />

      {/* section 2 */}
      {/* <section className="commonSection ready-top">
        <div className="container">
          <motion.div
            className="row"
            initial="hide"
            whileInView="view"
            viewport={{ once: true }}
            variants={{
              view: { opacity: 1, y: 0 },
              hide: { opacity: 0, y: 100 },
            }}
            transition={{ duration: 1 }}
          >
            <div className="col-lg-9 col-sm-8 col-md-9 mobile-center">
              <h2 className="sec_title white">
                let's get your project started!
              </h2>
            </div>
            <div className="col-lg-3 col-sm-4 col-md-3 text-right mobile-center">
              <Link className="common_btn bg-white-button" to="/contact">
                <span>let’s talk</span>
              </Link>
            </div>
          </motion.div>
        </div>
      </section> */}

      {/* section 3 */}
      {/* <motion.section
        className="commonSection"
        initial="hide"
        whileInView="view"
        viewport={{ once: true }}
        variants={{
          view: { opacity: 1, y: 0 },
          hide: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1 }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h4 className="sub_title red_color">Welcome to Orange Videos</h4>
              <h2 className="sec_title">
                creative video production
                <br /> with strategic marketing thinking.
              </h2>
              <p className="sec_desc color_aaa">
                We’re a video production agency helping businesses and brands
                achieve their goals with video.
              </p>
            </div>
          </div>
          <div className="row mb-3 d-flex justify-content-center">
            <div
              className="col-lg-3 col-sm-4 col-md-3"
              style={{
                marginBottom: 20,
              }}
            >
              <Link to="/video-strategy" className="icon_box_1 text-center">
                <div className="flipper">
                  <div className="front">
                    <img
                      alt="ov-img"
                      src="../assets-new/images/icons/grey/video-strategy.svg"
                    />
                    <h3>video strategy</h3>
                  </div>
                  <div className="back">
                    <img
                      alt="ov-img"
                      src="../assets-new/images/icons/white/video-strategy-white.svg"
                    />
                    <h3>video strategy</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-3 col-sm-4 col-md-3"
              style={{
                marginBottom: 20,
              }}
            >
              <Link
                to="/professional-corporate-film-video-production-agency-mumbai"
                className="icon_box_1 text-center"
              >
                <div className="flipper">
                  <div className="front">
                    <img
                      alt="ov-img"
                      src="../assets-new/images/icons/grey/corporate-video.svg"
                    />
                    <h3>corporate videos</h3>
                  </div>
                  <div className="back">
                    <img
                      alt="ov-img"
                      src="../assets-new/images/icons/white/corporate-video-white.svg"
                    />
                    <h3>corporate videos</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-3 col-sm-4 col-md-3"
              style={{
                marginBottom: 20,
              }}
            >
              <Link
                to="/product-video-makers-mumbai"
                className="icon_box_1 text-center"
              >
                <div className="flipper">
                  <div className="front">
                    <img
                      alt="ov-img"
                      src="../assets-new/images/icons/grey/product-videos.svg"
                    />
                    <h3>product videos</h3>
                  </div>
                  <div className="back">
                    <img
                      alt="ov-img"
                      src="../assets-new/images/icons/white/product-videos-white.svg"
                    />
                    <h3>product videos</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-3 col-sm-4 col-md-3"
              style={{
                marginBottom: 20,
              }}
            >
              <Link
                to="/aerial-videography-photography"
                className="icon_box_1 text-center"
              >
                <div className="flipper">
                  <div className="front">
                    <img
                      alt="ov-img"
                      src="../assets-new/images/icons/grey/aerial-photography.svg"
                    />
                    <h3>aerial videography &amp; photography</h3>
                  </div>
                  <div className="back">
                    <img
                      alt="ov-img"
                      src="../assets-new/images/icons/white/aerial-photography-white.svg"
                    />
                    <h3>aerial videography &amp; photography</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-3 col-sm-4 col-md-3"
              style={{
                marginBottom: 20,
              }}
            >
              <Link
                to="/2d-3d-animation-video-production-agency-mumbai"
                className="icon_box_1 text-center"
              >
                <div className="flipper">
                  <div className="front">
                    <img
                      alt="ov-img"
                      src="../assets-new/images/icons/grey/2d-3d-animateion.svg"
                    />
                    <h3>2d &amp; 3d animation</h3>
                  </div>
                  <div className="back">
                    <img
                      alt="ov-img"
                      src="../assets-new/images/icons/white/2d-3d-animateion-white.svg"
                    />
                    <h3>2d &amp; 3d animation</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-3 col-sm-4 col-md-3"
              style={{
                marginBottom: 20,
              }}
            >
              <Link
                to="/explainer-video-production-company-mumbai"
                className="icon_box_1 text-center"
              >
                <div className="flipper">
                  <div className="front">
                    <img
                      alt="ov-img"
                      src="../assets-new/images/icons/grey/explainer-video.svg"
                    />
                    <h3>explainer videos</h3>
                  </div>
                  <div className="back">
                    <img
                      alt="ov-img"
                      src="../assets-new/images/icons/white/explainer-video-white.svg"
                    />
                    <h3>explainer videos</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-3 col-sm-4 col-md-3"
              style={{
                marginBottom: 20,
              }}
            >
              <Link
                to="/digital-ad-film-making-agency-mumbai"
                className="icon_box_1 text-center"
              >
                <div className="flipper">
                  <div className="front">
                    <img
                      alt="ov-img"
                      src="../assets-new/images/icons/grey/digital-ad-films.svg"
                    />
                    <h3>digital ad films</h3>
                  </div>
                  <div className="back">
                    <img
                      alt="ov-img"
                      src="../assets-new/images/icons/white/digital-ad-filmswhite.svg"
                    />
                    <h3>digital ad films</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-3 col-sm-4 col-md-3"
              style={{
                marginBottom: 20,
              }}
            >
              <Link
                to="/corporate-photoshoots-agency-mumbai"
                className="icon_box_1 text-center"
              >
                <div className="flipper">
                  <div className="front">
                    <img
                      alt="ov-img"
                      src="../assets-new/images/icons/grey/photoshoot.svg"
                    />
                    <h3>photoshoots</h3>
                  </div>
                  <div className="back">
                    <img
                      alt="ov-img"
                      src="../assets-new/images/icons/white/photoshoot-white.svg"
                    />
                    <h3>photoshoots</h3>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </motion.section> */}

      {/* section 5 */}
      {/* <motion.section
        className="commonSection porfolio"
        initial="hide"
        whileInView="view"
        viewport={{ once: true }}
        variants={{
          view: { opacity: 1, y: 0 },
          hide: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1 }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h4 className="sub_title red_color">our portfolio</h4>
              <h2 className="sec_title">work showcase</h2>
              <p className="sec_desc">
                In this video portfolio, you'll find some of the best corporate
                videos we've created over the past couple of years.
                <br />
                Our secret recipe? Passion, a brand-oriented focus, and
                out-of-the-box thinking!{" "}
                <Link to="/contact">
                  <span
                    style={{
                      borderBottom: "1px solid #ff950d",
                      color: "#ff950d",
                    }}
                  >
                    Give us a try
                  </span>
                </Link>{" "}
                and see for yourself!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-6 col-md-4">
              <div className="singlefolio">
                <img src="../assets-new/images/WEBP/BPEP-new.webp" alt="" />
                <a
                  className="video_popup video_popup_custom"
                  href="https://www.youtube.com/watch?v=43Z7IIvuSCg"
                >
                  <i className="fa fa-play"></i>
                </a>
                <div className="folioHover">
                  <a className="cate" href="/">
                    corporate videos
                  </a>
                  <h4>
                    <Link to="/portfolio">BARING PRIVATE EQUITY</Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-4">
              <div className="singlefolio">
                <img
                  src="../assets-new/images/portfolio/corporate-video/fsc-new.jpg"
                  alt=""
                  loading="lazy"
                />
                <a
                  className="video_popup video_popup_custom"
                  href="https://www.youtube.com/watch?v=2DgXiAacGKk"
                >
                  <i className="fa fa-play"></i>
                </a>
                <div className="folioHover">
                  <a className="cate" href="/">
                    corporate videos
                  </a>
                  <h4>
                    <Link to="/portfolio">fsc</Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-4">
              <div className="singlefolio">
                <img
                  src="../assets-new/images/WEBP/total-new.webp"
                  alt=""
                  loading="lazy"
                />
                <a
                  className="video_popup video_popup_custom"
                  href="https://www.youtube.com/watch?v=2CujfgDAf3c"
                >
                  <i className="fa fa-play"></i>
                </a>
                <div className="folioHover">
                  <a className="cate" href="/">
                    corporate videos
                  </a>
                  <h4>
                    <Link to="/portfolio">TOTAL (TCAP)</Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-4">
              <div className="singlefolio">
                <img
                  src="../assets-new/images/portfolio/corporate-video/little-guru.jpg"
                  alt=""
                />
                <a
                  className="video_popup video_popup_custom"
                  href="https://www.youtube.com/watch?v=Co6-8Fzk6xE"
                >
                  <i className="fa fa-play"></i>
                </a>
                <div className="folioHover">
                  <a className="cate" href="/">
                    Graphic Animation
                  </a>
                  <h4>
                    <Link to="/portfolio">Little Guru</Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-4">
              <div className="singlefolio">
                <img src="../assets-new/images/WEBP/anunta-new.webp" alt="" />
                <a
                  className="video_popup video_popup_custom"
                  href="https://www.youtube.com/watch?v=YayTPXoSSio"
                >
                  <i className="fa fa-play"></i>
                </a>
                <div className="folioHover">
                  <a className="cate" href="/">
                    2d animation
                  </a>
                  <h4>
                    <Link to="/portfolio">Anunta</Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-4">
              <div className="singlefolio">
                <img src="../assets-new/images/WEBP/medtronic.webp" alt="" />
                <a
                  className="video_popup video_popup_custom"
                  href="https://www.youtube.com/watch?v=tKekjL76IAs"
                >
                  <i className="fa fa-play"></i>
                </a>
                <div className="folioHover">
                  <a className="cate" href="/">
                    whiteboard
                  </a>
                  <h4>
                    <Link to="/portfolio">medtronic</Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="container">
              <div className="col-md-12 text-center">
                <Link className="common_btn" to="/portfolio">
                  <span>view more</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </motion.section> */}

      {/* section 6 */}
      {/* <motion.div
        initial="hide"
        whileInView="view"
        viewport={{ once: true }}
        variants={{
          view: { opacity: 1, y: 0 },
          hide: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1 }}
      >
        <HomeSection6 />
      </motion.div> */}

      {/* section 8 */}
      {/* <motion.section
        className="commonSection blog"
        id="blog-sec"
        initial="hide"
        whileInView="view"
        viewport={{ once: true }}
        variants={{
          view: { opacity: 1, y: 0 },
          hide: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1 }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h4 className="sub_title red_color">our resources</h4>
              <h2 className="sec_title">latest insights</h2>
              <p className="sec_desc">
                The importance of video in the digital marketing world is hard
                to overestimate. But, how much do you really know about video
                marketing? In this section, we share our knowledge on how video
                can help you achieve your marketing objectives and more. All for
                FREE.
              </p>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-4 col-sm-6 col-md-4"
              style={{ height: "410px" }}
            >
              <div className="latestBlogItem">
                <div className="lbi_thumb">
                  <img
                    src="../assets-new/images/blog/vo-thumbnail.jpg"
                    alt=" Voice over and its role in videos"
                    style={{ height: "300px" }}
                  />
                </div>
                <div className="lbi_details">
                  <h2>
                    <Link to="/voice-over-and-its-role-in-videos">
                      Voice over and its role in videos
                    </Link>
                  </h2>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 col-md-4"
              style={{ height: "410px" }}
            >
              <div className="latestBlogItem">
                <div className="lbi_thumb">
                  <img
                    src="../assets-new/images/WEBP/prod-design-thumbnail.webp"
                    alt="Importance of Production Design"
                    style={{ height: "300px" }}
                  />
                </div>
                <div className="lbi_details">
                  <h2>
                    <Link to="/importance-of-production-design">
                      Importance of Production Design
                    </Link>
                  </h2>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 col-md-4"
              style={{ height: "410px" }}
            >
              <div className="latestBlogItem">
                <div className="lbi_thumb">
                  <img
                    src="../assets-new/images/WEBP/different-types-of-shots-thumb.webp"
                    alt="Types of Shots in Live Shoot Videos"
                    style={{ height: "300px" }}
                  />
                </div>
                <div className="lbi_details">
                  <h2>
                    <Link to="/types-of-shots-in-live-shoot-videos">
                      Types of Shots in Live Shoot Videos
                    </Link>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="container">
              <div className="col-md-12 text-center">
                <Link className="common_btn" to="/insights">
                  <span>explore more</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </motion.section> */}

      {/* section 9 */}
      {/* <motion.section
        className="commonSection client"
        initial="hide"
        whileInView="view"
        viewport={{ once: true }}
        variants={{
          view: { opacity: 1, y: 0 },
          hide: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1 }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h4 className="sub_title red_color">our clients</h4>
              <h2 className="sec_title">they trust us</h2>
              <p className="sec_desc">
                We are committed to providing our customers with exceptional
                service while offering our employees the best training.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12" style={{ display: "none" }}>
              <div className="client_slider owl-carousel owl-theme owl-hidden owl-loaded">
                <div className="owl-stage-outer">
                  <div
                    className="owl-stage"
                    style={{
                      transform: "translate3d(-32.5px, 0px, 0px)",
                      transition: "all 0.25s ease 0s",
                      width: "260px",
                    }}
                  >
                    <div
                      className="owl-item"
                      style={{ width: "2.5px", marginRight: "30px" }}
                    >
                      <div className="singleClient">
                        <a href="/" onClick={(e) => e.preventDefault()}>
                          <img
                            src="/assets-new/images/client/coloured/1.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                    <div
                      className="owl-item active"
                      sstyle={{ width: "2.5px", marginRight: "30px" }}
                    >
                      <div className="singleClient">
                        <a href="/" onClick={(e) => e.preventDefault()}>
                          <img
                            src="/assets-new/images/client/coloured/2.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                    <div
                      className="owl-item active"
                      sstyle={{ width: "2.5px", marginRight: "30px" }}
                    >
                      <div className="singleClient">
                        <a href="/" onClick={(e) => e.preventDefault()}>
                          <img
                            src="/assets-new/images/client/coloured/3.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                    <div
                      className="owl-item active"
                      sstyle={{ width: "2.5px", marginRight: "30px" }}
                    >
                      <div className="singleClient">
                        <a href="/" onClick={(e) => e.preventDefault()}>
                          <img
                            src="/assets-new/images/client/coloured/4.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                    <div
                      className="owl-item active"
                      sstyle={{ width: "2.5px", marginRight: "30px" }}
                    >
                      <div className="singleClient">
                        <a href="/" onClick={(e) => e.preventDefault()}>
                          <img
                            src="/assets-new/images/client/coloured/5.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                    <div
                      className="owl-item"
                      sstyle={{ width: "2.5px", marginRight: "30px" }}
                    >
                      <div className="singleClient">
                        <a href="/" onClick={(e) => e.preventDefault()}>
                          <img
                            src="/assets-new/images/client/coloured/6.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                    <div
                      className="owl-item"
                      sstyle={{ width: "2.5px", marginRight: "30px" }}
                    >
                      <div className="singleClient">
                        <a href="/" onClick={(e) => e.preventDefault()}>
                          <img
                            src="/assets-new/images/client/coloured/7.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                    <div
                      className="owl-item"
                      sstyle={{ width: "2.5px", marginRight: "30px" }}
                    >
                      <div className="singleClient">
                        <a href="/" onClick={(e) => e.preventDefault()}>
                          <img
                            src="/assets-new/images/client/coloured/8.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="owl-controls">
                  <div className="owl-nav">
                    <div className="owl-prev" style={{ display: "none" }}>
                      prev
                    </div>
                    <div className="owl-next" style={{ display: "none" }}>
                      next
                    </div>
                  </div>
                  <div className="owl-dots">
                    <div className="owl-dot active">
                      <span></span>
                    </div>
                    <div className="owl-dot">
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 desktop-logo">
              <img
                src="../assets-new/images/client/client-logo-desktop feb 2023.jpg"
                className="img-fluid"
                loading="lazy"
                alt=""
              />
            </div>
            <div className="col-12 mobile-logo text-center">
              <img
                src="../assets-new/images/WEBP/mobile_logo_1.webp"
                className="img-fluid"
                alt=""
              />
              <img
                src="../assets-new/images/WEBP/mobile_logo_2.webp"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </motion.section> */}
    </>
  );
};

export default Home;

import React, { useEffect, useRef, useState } from "react";
import "./styles/styles.scss";
import ourVideoProcess from "../../assets-new/images/animation_banner_v2.png";
import { useLocation, useNavigate } from "react-router-dom";
import icon1 from "../../assets-new/images/1_original artwork.svg";
import icon2 from "../../assets-new/images/1_best sound quality.svg";
import icon3 from "../../assets-new/images/1_script writing.svg";
import icon4 from "../../assets-new/images/1_quick turn around.svg";
import icon5 from "../../assets-new/images/1_affordable price.svg";
import icon6 from "../../assets-new/images/1_unlimited revisions.svg";
import ScrollSpy from "react-ui-scrollspy";
import WorkShowcaseImg1 from "../../assets-new/images/idex_3d_.png";
import WorkShowcaseImg2 from "../../assets-new/images/acuity_kp.png";
import WorkShowcaseImg3 from "../../assets-new/images/little guru.png";
import WorkShowcaseImg4 from "../../assets-new/images/iball_.png";
import WorkShowcaseImg5 from "../../assets-new/images/anunta.png";
import WorkShowcaseImg6 from "../../assets-new/images/medtronic.png";
import WorkShowcaseImg7 from "../../assets-new/images/sharekhan_v23.png";
import WorkShowcaseImg8 from "../../assets-new/images/coats.png";
import WorkShowcaseImg9 from "../../assets-new/images/book_air_ambulance.png";
import TheyTrustUs from "./TheyTrustUs";
import FaqSection from "./FaqSection";
import OvInfo from "./OvInfo";
import LpFooter from "./LpFooter";
import { AppUseContext } from "../AppContext";
import VideoPopup from "../VideoPopup";
import { FaPlay, FaPlayCircle } from "react-icons/fa";
// import TopForm from "./TopForm";
// import Sideform from "./SideForm";
import MetaData from "../MetaData";
import TestSideForm from "./TestSideForm";
import greyIcon1 from "../../assets-new/images/icons/grey/video-strategy.svg";
import greyIcon2 from "../../assets-new/images/icons/grey/corporate-video.svg";
import greyIcon3 from "../../assets-new/images/icons/grey/product-videos.svg";
import greyIcon4 from "../../assets-new/images/icons/grey/aerial-photography.svg";
import greyIcon5 from "../../assets-new/images/icons/grey/2d-3d-animateion.svg";
import greyIcon6 from "../../assets-new/images/icons/grey/explainer-video.svg";
import greyIcon7 from "../../assets-new/images/icons/grey/digital-ad-films.svg";
import greyIcon8 from "../../assets-new/images/icons/grey/photoshoot.svg";
import whiteIcon1 from "../../assets-new/images/icons/white/video-strategy-white.svg";
import whiteIcon2 from "../../assets-new/images/icons/white/corporate-video-white.svg";
import whiteIcon3 from "../../assets-new/images/icons/white/product-videos-white.svg";
import whiteIcon4 from "../../assets-new/images/icons/white/aerial-photography-white.svg";
import whiteIcon5 from "../../assets-new/images/icons/white/2d-3d-animateion-white.svg";
import whiteIcon6 from "../../assets-new/images/icons/white/explainer-video-white.svg";
import whiteIcon7 from "../../assets-new/images/icons/white/digital-ad-filmswhite.svg";
import whiteIcon8 from "../../assets-new/images/icons/white/photoshoot-white.svg";

const TestLandingPage = () => {
  const { setIsLandingPageVisible, landingFormId } = AppUseContext();
  const { toggleVideoPopup } = AppUseContext();

  const pathname = useLocation();

  const [locationName, setLocationName] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      pathname.pathname === "/animation-explainer-video-bangalore" ||
      pathname.pathname === "/animation-explainer-video-bangalore-test"
    ) {
      setLocationName("Bangalore");
    } else if (
      pathname.pathname === "/animation-explainer-video-mumbai" ||
      pathname.pathname === "/animation-explainer-video-mumbai-test"
    ) {
      setLocationName("Mumbai");
    } else if (
      pathname.pathname === "/animation-explainer-video-pune" ||
      pathname.pathname === "/animation-explainer-video-pune-test"
    ) {
      setLocationName("");
    }
  }, [pathname]);

  useEffect(() => {
    if (pathname.pathname === "/animation-explainer-video-mumbai/") {
      navigate("/animation-explainer-video-mumbai");
    } else if (pathname.pathname === "/animation-explainer-video-bangalore/") {
      navigate("/animation-explainer-video-bangalore");
    } else if (pathname.pathname === "/animation-explainer-video-pune/") {
      navigate("/animation-explainer-video-pune");
    }
    // eslint-disable-next-line

    setIsLandingPageVisible(true);
    // eslint-disable-next-line
  }, [pathname]);

  const scriptRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/shell.js";
    script.async = true; // Add async attribute for better performance

    script.onload = () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "46497548",
          formId: landingFormId,
          target: "#hubspotForm",
        });
      }
    };

    scriptRef.current = script;
    document.body.appendChild(script);

    return () => {
      if (scriptRef.current) {
        document.body.removeChild(scriptRef.current);
      }
    };
  }, [landingFormId]);

  return (
    <>
      <MetaData
        pageTitle="Animation Video Production Company in Mumbai, Delhi & Bangalore | Orange Videos"
        metaDdescription="Orange videos is a leading top video production company based in Mumbai. From corporate to explainer Videos, we create high class videos."
      />
      {/* side form below */}
      <TestSideForm hubspotFormId={landingFormId} />

      <ScrollSpy>
        <header className="header">
          <section className="top-navbar">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7">
                  <div className="left-topbar d-flex align-items-center w-100 h-100 d-none d-lg-block">
                    <p>
                      {locationName === "" ? "Pune’s " : `${locationName}'s `}
                      Trusted Animation Video Production Agency
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 align-items-center">
                  <div
                    className="right-topbar d-flex align-items-center justify-content-end w-100 h-100"
                    style={{
                      justifyContent: "flex-end",
                    }}
                  >
                    <ul className="d-flex align-items-center h-100">
                      <li>
                        <a
                          href="mailto:sales@orangevideos.in"
                          className="hvr-icon-pop"
                        >
                          sales@orangevideos.in
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!--begin navbar-fixed-top --> */}
          {/* <nav className="navbar navbar-default navbar-fixed-top">
          <div className="container">
            <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand" href="#">
            <img src="images/logo.png" />
            </a>
            <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
                >
                <span className="navbar-toggler-icon">
                  <i className="fas fa-bars"></i>
                  </span>
                  </button>
                  </nav>
                  </div>
                </nav> */}
        </header>

        <section className="home-section landing-home-page" id="home">
          <div className="home-section-overlay"></div>

          {/* <!--begin container --> */}
          <div className="container">
            {/* <!--begin row --> */}
            <div className="row">
              {/* <!--begin col-md-5--> */}
              <div className="col-md-7 header-contain">
                <h1>ANIMATION VIDEOS</h1>
                <h3 className="mb-2">BRING YOUR BRAND AND BUSINESS TO LIFE.</h3>
                <p className="banner_para">
                  WIN MORE CUSTOMERS, WITH{" "}
                  {locationName === "" ? "Pune" : locationName}’S BEST VIDEO
                  PRODUCTION COMPANY.
                </p>
                {/* <!--<div className="singlefolio" style="width: 250px!important;">--> */}
                {/* <!--<input value="Watch Showreel" className="home-btn" type="submit" style="width:100%!important;">--> */}
                {/* <!--<a className="home-btn plus-btn">Watch Showreel</a>--> */}
                {/* <!--<a className="video_popup video_popup_custom " href="https://www.youtube.com/watch?v=JGV05ck7U0g" style="top:0%!important;width:100%!important;height:53px!important;"><input value="Watch Showreel" className="home-btn" type="submit" style="width:100%!important;"></a>--> */}
                <a
                  className="show-btn mt-4  video_popup"
                  href="https://www.youtube.com/watch?v=JGV05ck7U0g"
                  onClick={(e) => {
                    toggleVideoPopup({
                      videoSrc:
                        "https://www.youtube.com/embed/JGV05ck7U0g?si=CeF2FuVJbZ8s4yvc",
                      param: e,
                    });
                  }}
                >
                  <span>Watch our showreel </span>
                  {/* <i className="button-effect fas fa-play-circle"></i> */}
                  <FaPlayCircle className="button-effect" />
                </a>
                {/* <!--</div>--> */}
              </div>

              <div className="col-md-5 text-center form-media">
                {/* <!--begin register-form-wrapper--> */}
                <div className="register-form-wrapper">
                  <h1 className="mb-2">Have a project brief?</h1>

                  <p className="mb-4">Get an estimate within 24 hours.</p>

                  {/* <!--begin form--> */}
                  <div>
                    {/* <!--begin success message --> */}
                    <p
                      className="register_success_box"
                      style={{ display: "none" }}
                    >
                      Just drop a message. We will get back within 24 hours.
                    </p>
                    {/* <!--end success message --> */}

                    {/* <!--begin register form --> */}

                    <div id="hubspotForm"></div>
                    {/* <TopForm /> */}
                    {/* <!--end register form --> */}
                  </div>
                  {/* <!--end form--> */}
                </div>
                {/* <!--end register-form-wrapper--> */}
              </div>
              {/* <!--end col-md-5--> */}
            </div>
            {/* <!--end row --> */}
          </div>
          {/* <!--end container --> */}
        </section>

        <section className="countdown-section small-paddings">
          {/* <!--begin container --> */}
          <div className="container">
            {/* <!--begin row --> */}
            <div className="row">
              {/* <!--begin col-md-12--> */}
              <div className="col-md-12 text-center">
                <div className="row d-flex justify-content-center">
                  <div className="col-md-2 countdown-num">
                    <h3>500+</h3>
                    <p>videos</p>
                  </div>
                  <div className="col-md-2 countdown-num">
                    <h3>12+</h3>
                    <p>countries</p>
                  </div>
                  <div className="col-md-2 countdown-num">
                    <h3>150+</h3>
                    <p>happy clients</p>
                  </div>
                  <div className="col-md-2 countdown-num">
                    <h3>1000+</h3>
                    <p>minutes of videos delivered</p>
                  </div>
                  <div className="col-md-2 countdown-num">
                    <h3>8+</h3>
                    <p>languages</p>
                  </div>
                </div>
              </div>
              {/* <!--end col-md-12--> */}
            </div>
            {/* <!--end row --> */}
          </div>
          {/* <!--end container --> */}
        </section>

        <section
          className="commonSection section-white featured"
          id="corporate_video"
        >
          <div className="container">
            <div className="row d-flex justify-content-center">
              <h2 className="sec_title">Videos that taste different!</h2>
              <div className="col-lg-5 col-sm-12  col-md-5">
                <div className="features_content">
                  {/* <!--<h4 className="sub_title red_color">our approach</h4>--> */}
                  <p className="sec_desc orange-color">
                    How do we make our videos?
                  </p>
                  <div className="">
                    {/* <!--<div className="f_count">01</div>--> */}
                    {/* <!--<h3>Strategy</h3>--> */}
                    <p className="sec_desc">
                      Discover our creative and unique video production process
                    </p>

                    <p className="sec_desc">
                      If you’re thinking about starting a video project and are
                      wondering “what should I expect?” - Here is a detailed
                      overview of the explainer video animation process that
                      we’ve created to give you a better understanding of how
                      this thing works.
                    </p>

                    <p className="sec_desc">
                      Animated videos allow you to transmit your company’s
                      message with ease and simplicity, even if your product or
                      service is super complex to understand at first sight.
                      With animation, the story isn’t restricted by the limits
                      of reality like in a live-action production – you can let
                      your imagination fly and find the best way to attract and
                      convert viewers!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 col-md-6 noPaddingRight">
                <div className="features_img lp-right-overview">
                  <img src={ourVideoProcess} alt="" className="img-fluid" />
                  <div className="lp-overview-right-popup-btn">
                    <span
                      onClick={(e) => {
                        toggleVideoPopup({
                          videoSrc:
                            "https://www.youtube.com/embed/jlobpCWVsUc?si=H6fS8AMFB5Yex-9v",
                          param: e,
                        });
                      }}
                    >
                      <FaPlay />
                    </span>
                  </div>
                  {/* <a
                    className="video_popup video_popup_custom"
                    href="https://youtu.be/jlobpCWVsUc?si=gqQRsIdNOmN0tGWq"
                  >
                    <i className="fa fa-play"></i>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="commonSection gray-bg" id="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h4 className="sub_title red_color">
                  Welcome to Orange Videos
                </h4>
                <h2 className="sec_title">
                  creative video production
                  <br /> with strategic marketing thinking.
                </h2>
                <p className="sec_desc color_aaa">
                  We’re a video production agency helping businesses and brands
                  achieve their goals with video.
                </p>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div
                className="col-lg-3 col-sm-4 col-md-3"
                style={{
                  marginBottom: 20,
                }}
              >
                <span className="icon_box_1 text-center">
                  <div className="flipper">
                    <div className="front">
                      <img alt="ov-img" src={greyIcon1} />
                      <h3>video strategy</h3>
                    </div>
                    <div className="back">
                      <img alt="ov-img" src={whiteIcon1} />
                      <h3>video strategy</h3>
                    </div>
                  </div>
                </span>
              </div>
              <div
                className="col-lg-3 col-sm-4 col-md-3"
                style={{
                  marginBottom: 20,
                }}
              >
                <span
                  to="/professional-corporate-film-video-production-agency-mumbai"
                  className="icon_box_1 text-center"
                >
                  <div className="flipper">
                    <div className="front">
                      <img alt="ov-img" src={greyIcon2} />
                      <h3>corporate videos</h3>
                    </div>
                    <div className="back">
                      <img alt="ov-img" src={whiteIcon2} />
                      <h3>corporate videos</h3>
                    </div>
                  </div>
                </span>
              </div>
              <div
                className="col-lg-3 col-sm-4 col-md-3"
                style={{
                  marginBottom: 20,
                }}
              >
                <span
                  to="/product-video-makers-mumbai"
                  className="icon_box_1 text-center"
                >
                  <div className="flipper">
                    <div className="front">
                      <img alt="ov-img" src={greyIcon3} />
                      <h3>product videos</h3>
                    </div>
                    <div className="back">
                      <img alt="ov-img" src={whiteIcon3} />
                      <h3>product videos</h3>
                    </div>
                  </div>
                </span>
              </div>
              <div
                className="col-lg-3 col-sm-4 col-md-3"
                style={{
                  marginBottom: 20,
                }}
              >
                <span
                  to="/aerial-videography-photography"
                  className="icon_box_1 text-center"
                >
                  <div className="flipper">
                    <div className="front">
                      <img alt="ov-img" src={greyIcon4} />
                      <h3>aerial videography &amp; photography</h3>
                    </div>
                    <div className="back">
                      <img alt="ov-img" src={whiteIcon4} />
                      <h3>aerial videography &amp; photography</h3>
                    </div>
                  </div>
                </span>
              </div>
              {/* </div>
          <div className="row d-flex"> */}
              <div
                className="col-lg-3 col-sm-4 col-md-3"
                style={{
                  marginBottom: 20,
                }}
              >
                <span
                  to="/2d-3d-animation-video-production-agency-mumbai"
                  className="icon_box_1 text-center"
                >
                  <div className="flipper">
                    <div className="front">
                      <img alt="ov-img" src={greyIcon5} />
                      <h3>2d &amp; 3d animation</h3>
                    </div>
                    <div className="back">
                      <img alt="ov-img" src={whiteIcon5} />
                      <h3>2d &amp; 3d animation</h3>
                    </div>
                  </div>
                </span>
              </div>
              <div
                className="col-lg-3 col-sm-4 col-md-3"
                style={{
                  marginBottom: 20,
                }}
              >
                <span
                  to="/explainer-video-production-company-mumbai"
                  className="icon_box_1 text-center"
                >
                  <div className="flipper">
                    <div className="front">
                      <img alt="ov-img" src={greyIcon6} />
                      <h3>explainer videos</h3>
                    </div>
                    <div className="back">
                      <img alt="ov-img" src={whiteIcon6} />
                      <h3>explainer videos</h3>
                    </div>
                  </div>
                </span>
              </div>
              <div
                className="col-lg-3 col-sm-4 col-md-3"
                style={{
                  marginBottom: 20,
                }}
              >
                <span
                  to="/digital-ad-film-making-agency-mumbai"
                  className="icon_box_1 text-center"
                >
                  <div className="flipper">
                    <div className="front">
                      <img alt="ov-img" src={greyIcon7} />
                      <h3>digital ad films</h3>
                    </div>
                    <div className="back">
                      <img alt="ov-img" src={whiteIcon7} />
                      <h3>digital ad films</h3>
                    </div>
                  </div>
                </span>
              </div>
              <div
                className="col-lg-3 col-sm-4 col-md-3"
                style={{
                  marginBottom: 20,
                }}
              >
                <span
                  to="/corporate-photoshoots-agency-mumbai"
                  className="icon_box_1 text-center"
                >
                  <div className="flipper">
                    <div className="front">
                      <img alt="ov-img" src={greyIcon8} />
                      <h3>photoshoots</h3>
                    </div>
                    <div className="back">
                      <img alt="ov-img" src={whiteIcon8} />
                      <h3>photoshoots</h3>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section-white landing-page-hire-ov commonSection"
          id="why-hire-us"
        >
          {/* <!--begin container --> */}
          <div className="container">
            {/* <!--begin row --> */}
            <div className="row">
              {/* <!--begin col-md-12 --> */}
              <div className="col-md-12 text-center margin-bottom-30">
                <h4 className="sub_title red_color">many reasons</h4>
                <h2 className="sec_title">Why Hire Orange Videos?</h2>

                <p className="sec_desc">
                  6 compelling reasons to partner with us
                </p>
              </div>
              {/* <!--end col-md-12 --> */}
            </div>
            {/* <!--end row --> */}
          </div>
          {/* <!--end container --> */}

          {/* <!--begin services-wrapper --> */}
          <div className="services-wrapper">
            {/* <!--begin container --> */}
            <div className="container">
              {/* <!--begin row --> */}
              <div className="row">
                {/* <!--begin col-md-4 --> */}
                <div className="col-md-4">
                  <div
                    className="main-services wow fadeIn"
                    data-wow-delay="0.25s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.25s",
                      animationName: "fadeIn",
                    }}
                  >
                    {/* <!--<i className="fas fa-rocket"></i>--> */}
                    <img src={icon1} className="hire_icon" alt="" />

                    <h4>ORIGINAL ARTWORK</h4>

                    <p className="sec_desc">
                      We create 100% original graphics and other inputs used in
                      our video.
                    </p>
                  </div>
                </div>
                {/* <!--end col-md-4 --> */}

                {/* <!--begin col-md-4 --> */}
                <div className="col-md-4">
                  <div
                    className="main-services wow fadeIn"
                    data-wow-delay="0.50s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.5s",
                      animationName: "fadeIn",
                    }}
                  >
                    {/* <!--<i className="fas fa-chart-line"></i>--> */}
                    <img src={icon2} className="hire_icon" alt="" />

                    <h4>BEST SOUND QUALITY</h4>

                    <p className="sec_desc">
                      Voice is the soul of any video. We provide the best
                      quality voice overs by professional voice over artists
                      from all over the world.
                    </p>
                  </div>
                </div>
                {/* <!--end col-md-4 --> */}

                {/* <!--begin col-md-4 --> */}
                <div className="col-md-4">
                  <div
                    className="main-services wow fadeIn"
                    data-wow-delay="0.75s"
                  >
                    {/* <!--<i className="fas fa-chart-pie"></i>--> */}
                    <img src={icon3} className="hire_icon" alt="" />

                    <h4>PROFESSIONAL SCRIPT WRITING</h4>

                    <p className="sec_desc">
                      Content plays a vital role in videos, we assure that you
                      get a marvellous script that gets your point across to
                      your audience succinctly and efficiently.
                    </p>
                  </div>
                </div>
                {/* <!--end col-md-4 --> */}

                {/* <!--begin col-md-4 --> */}
                <div className="col-md-4">
                  <div className="main-services wow fadeIn" data-wow-delay="1s">
                    {/* <!--<i className="fas fa-anchor"></i>--> */}
                    <img src={icon4} className="hire_icon" alt="" />

                    <h4>QUICK TURNAROUND TIME</h4>

                    <p className="sec_desc">
                      You can ask for "EXPRESS DELIVERY" in case of a hurry. The
                      video will be delivered within 7-10 days.
                    </p>
                  </div>
                </div>
                {/* <!--end col-md-4 --> */}

                {/* <!--begin col-md-4 --> */}
                <div className="col-md-4">
                  <div
                    className="main-services wow fadeIn"
                    data-wow-delay="1.25s"
                  >
                    {/* <!--<i className="fas fa-thumbs-up"></i>--> */}
                    <img src={icon5} className="hire_icon" alt="" />

                    <h4>AFFORDABLE PRICE</h4>

                    <p className="sec_desc">
                      We cater to businesses of all sizes - startups, small
                      businesses, and big brands - and have a video that fits
                      every budget.
                    </p>
                  </div>
                </div>
                {/* <!--end col-md-4 --> */}

                {/* <!--begin col-md-4 --> */}
                <div className="col-md-4">
                  <div
                    className="main-services wow fadeIn"
                    data-wow-delay="1.5s"
                  >
                    {/* <!--<i className="fab fa-rocketchat"></i>--> */}
                    <img src={icon6} className="hire_icon" alt="" />

                    <h4>UNLIMITED REVISIONS</h4>

                    <p className="sec_desc">
                      Client satisfaction is our priority, that's why we provide
                      unlimited corrections until you are satisfied.{" "}
                    </p>
                  </div>
                </div>
                {/* <!--end col-md-4 --> */}
              </div>
              {/* <!--end row --> */}
            </div>
            {/* <!--end container --> */}
          </div>
          {/* <!--end services-wrapper --> */}
        </section>

        <section className="commonSection porfolio gray-bg" id="our-work">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h4 className="sub_title red_color">OUR PORTFOLIO</h4>
                <h2 className="sec_title">work showcase</h2>
                {/* <p className="sec_desc">
                  In this video portfolio, you'll find some of the best
                  corporate videos we've created over the past couple of years.
                  <br />
                  Our secret recipe? Passion, a brand-oriented focus, and
                  out-of-the-box thinking!{" "}
                  <Link to="/contact">
                    <span
                      style={{
                        borderBottom: "1px solid #ff950d",
                        color: "#ff950d",
                      }}
                    >
                      Give us a try
                    </span>
                  </Link>{" "}
                  and see for yourself!
                </p> */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-6 col-md-4">
                <div className="singlefolio">
                  <img src={WorkShowcaseImg1} alt="" />
                  <a
                    className="video_popup video_popup_custom"
                    href="https://www.youtube.com/watch?v=Vom5zM9Q_nU"
                    onClick={(e) => {
                      toggleVideoPopup({
                        videoSrc:
                          "https://www.youtube.com/embed/Vom5zM9Q_nU?si=cQ90wcDColfBEKZT",
                        param: e,
                      });
                    }}
                  >
                    <i className="fa fa-play"></i>
                  </a>
                  {/* <div className="folioHover">
                    <a className="cate" href="/">
                      corporate videos
                    </a>
                    <h4>
                      <Link to="/portfolio">BARING PRIVATE EQUITY</Link>
                    </h4>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-4">
                <div className="singlefolio">
                  <img src={WorkShowcaseImg2} alt="" loading="lazy" />
                  <a
                    className="video_popup video_popup_custom"
                    href="https://www.youtube.com/watch?v=FDlwTvw7Zp4"
                    onClick={(e) => {
                      toggleVideoPopup({
                        videoSrc:
                          "https://www.youtube.com/embed/FDlwTvw7Zp4?si=6j6eWDAZu-PkiL71",
                        param: e,
                      });
                    }}
                  >
                    <i className="fa fa-play"></i>
                  </a>
                  {/* <div className="folioHover">
                    <a className="cate" href="/">
                      corporate videos
                    </a>
                    <h4>
                      <Link to="/portfolio">fsc</Link>
                    </h4>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-4">
                <div className="singlefolio">
                  <img src={WorkShowcaseImg3} alt="" loading="lazy" />
                  <a
                    className="video_popup video_popup_custom"
                    href="https://www.youtube.com/watch?v=Co6-8Fzk6xE&t"
                    onClick={(e) => {
                      toggleVideoPopup({
                        videoSrc:
                          "https://www.youtube.com/embed/Co6-8Fzk6xE?si=dbBD6mtx7BY0MH1D",
                        param: e,
                      });
                    }}
                  >
                    <i className="fa fa-play"></i>
                  </a>
                  {/* <div className="folioHover">
                    <a className="cate" href="/">
                      corporate videos
                    </a>
                    <h4>
                      <Link to="/portfolio">TOTAL (TCAP)</Link>
                    </h4>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-4">
                <div className="singlefolio">
                  <img src={WorkShowcaseImg4} alt="" />
                  <a
                    className="video_popup video_popup_custom"
                    href="https://www.youtube.com/watch?v=QD1vOiHz0Y4"
                    onClick={(e) => {
                      toggleVideoPopup({
                        videoSrc:
                          "https://www.youtube.com/embed/QD1vOiHz0Y4?si=41RKkU5poGYhPbni",
                        param: e,
                      });
                    }}
                  >
                    <i className="fa fa-play"></i>
                  </a>
                  {/* <div className="folioHover">
                    <a className="cate" href="/">
                      Graphic Animation
                    </a>
                    <h4>
                      <Link to="/portfolio">Little Guru</Link>
                    </h4>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-4">
                <div className="singlefolio">
                  <img src={WorkShowcaseImg5} alt="" />
                  <a
                    className="video_popup video_popup_custom"
                    href="https://www.youtube.com/watch?v=YayTPXoSSio"
                    onClick={(e) => {
                      toggleVideoPopup({
                        videoSrc:
                          "https://www.youtube.com/embed/YayTPXoSSio?si=0vhsZk2OeKptBkpG",
                        param: e,
                      });
                    }}
                  >
                    <i className="fa fa-play"></i>
                  </a>
                  {/* <div className="folioHover">
                    <a className="cate" href="/">
                      2d animation
                    </a>
                    <h4>
                      <Link to="/portfolio">Anunta</Link>
                    </h4>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-4">
                <div className="singlefolio">
                  <img src={WorkShowcaseImg6} alt="" />
                  <a
                    className="video_popup video_popup_custom"
                    href="https://www.youtube.com/watch?v=tKekjL76IAs"
                    onClick={(e) => {
                      toggleVideoPopup({
                        videoSrc:
                          "https://www.youtube.com/embed/tKekjL76IAs?si=vcVIXUF7mGu-LP_q",
                        param: e,
                      });
                    }}
                  >
                    <i className="fa fa-play"></i>
                  </a>
                  {/* <div className="folioHover">
                    <a className="cate" href="/">
                      whiteboard
                    </a>
                    <h4>
                      <Link to="/portfolio">medtronic</Link>
                    </h4>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-4">
                <div className="singlefolio">
                  <img src={WorkShowcaseImg7} alt="" />
                  <a
                    className="video_popup video_popup_custom"
                    href="https://www.youtube.com/watch?v=NFrMYfVd3lE"
                    onClick={(e) => {
                      toggleVideoPopup({
                        videoSrc:
                          "https://www.youtube.com/embed/NFrMYfVd3lE?si=jkZiQqD3waErcEMQ",
                        param: e,
                      });
                    }}
                  >
                    <i className="fa fa-play"></i>
                  </a>
                  {/* <div className="folioHover">
                    <a className="cate" href="/">
                      whiteboard
                    </a>
                    <h4>
                      <Link to="/portfolio">medtronic</Link>
                    </h4>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-4">
                <div className="singlefolio">
                  <img src={WorkShowcaseImg8} alt="" />
                  <a
                    className="video_popup video_popup_custom"
                    href="https://www.youtube.com/watch?v=dFbCHakshNo"
                    onClick={(e) => {
                      toggleVideoPopup({
                        videoSrc:
                          "https://www.youtube.com/embed/dFbCHakshNo?si=a5K8V6jLw9N6jVu6",
                        param: e,
                      });
                    }}
                  >
                    <i className="fa fa-play"></i>
                  </a>
                  {/* <div className="folioHover">
                    <a className="cate" href="/">
                      whiteboard
                    </a>
                    <h4>
                      <Link to="/portfolio">medtronic</Link>
                    </h4>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-4">
                <div className="singlefolio">
                  <img src={WorkShowcaseImg9} alt="" />
                  <a
                    className="video_popup video_popup_custom"
                    href="https://www.youtube.com/watch?v=TAiDgf763iY"
                    onClick={(e) => {
                      toggleVideoPopup({
                        videoSrc:
                          "https://www.youtube.com/embed/TAiDgf763iY?si=0_hCBrE2EgDJjZZc",
                        param: e,
                      });
                    }}
                  >
                    <i className="fa fa-play"></i>
                  </a>
                  {/* <div className="folioHover">
                    <a className="cate" href="/">
                      whiteboard
                    </a>
                    <h4>
                      <Link to="/portfolio">medtronic</Link>
                    </h4>
                  </div> */}
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="container">
                <div className="col-md-12 text-center">
                  <Link className="common_btn" to="/portfolio">
                    <span>view more</span>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </section>

        <TheyTrustUs />

        <FaqSection />

        <OvInfo />

        <LpFooter />
      </ScrollSpy>

      <VideoPopup />
    </>
  );
};

export default TestLandingPage;

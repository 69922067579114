import React from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import { GrNext, GrPrevious } from "react-icons/gr";
import { Autoplay, EffectFade, EffectCoverflow } from "swiper/modules";
import "swiper/css/effect-coverflow";
import ClientImg1 from "../../assets-new/images/home-client-carousel/client-section-logo-1.png";
import ClientImg2 from "../../assets-new/images/home-client-carousel/client-section-logo-2.png";
import ClientImg3 from "../../assets-new/images/home-client-carousel/client-section-logo-3.png";
import ClientImg4 from "../../assets-new/images/home-client-carousel/client-section-logo-4.png";
import ClientImg5 from "../../assets-new/images/home-client-carousel/client-section-logo-5.png";
import ClientImg6 from "../../assets-new/images/home-client-carousel/client-section-logo-6.png";
import ClientImg7 from "../../assets-new/images/home-client-carousel/client-section-logo-7.png";
import ClientImg8 from "../../assets-new/images/home-client-carousel/client-section-logo-8.png";
import ClientImg9 from "../../assets-new/images/home-client-carousel/client-section-logo-9.png";
import ClientImg10 from "../../assets-new/images/home-client-carousel/client-section-logo-10.png";
// import ClientImg11 from "../../assets-new/images/home-client-carousel/client-section-logo-11.png";
import ClientImg12 from "../../assets-new/images/home-client-carousel/client-section-logo-12.png";
import ClientImg13 from "../../assets-new/images/home-client-carousel/client-section-logo-13.png";
import ClientImg14 from "../../assets-new/images/home-client-carousel/client-section-logo-14.png";
import ClientImg15 from "../../assets-new/images/home-client-carousel/client-section-logo-15.png";
import ClientImg16 from "../../assets-new/images/home-client-carousel/client-section-logo-16.png";
import ClientImg17 from "../../assets-new/images/home-client-carousel/client-section-logo-17.png";
import ClientImg18 from "../../assets-new/images/home-client-carousel/client-section-logo-18.png";
import ClientImg19 from "../../assets-new/images/home-client-carousel/client-section-logo-19.png";
import ClientImg20 from "../../assets-new/images/home-client-carousel/client-section-logo-20.png";
import ClientImg21 from "../../assets-new/images/home-client-carousel/client-section-logo-21.png";
import ClientImg22 from "../../assets-new/images/home-client-carousel/client-section-logo-22.png";
import ClientImg23 from "../../assets-new/images/home-client-carousel/client-section-logo-23.png";
import ClientImg24 from "../../assets-new/images/home-client-carousel/client-section-logo-24.png";
import ClientImg25 from "../../assets-new/images/home-client-carousel/client-section-logo-25.png";
import ClientImg26 from "../../assets-new/images/home-client-carousel/client-section-logo-26.png";
import ClientImg27 from "../../assets-new/images/home-client-carousel/client-section-logo-27.png";
import ClientImg28 from "../../assets-new/images/home-client-carousel/client-section-logo-28.png";
import ClientImg29 from "../../assets-new/images/home-client-carousel/client-section-logo-29.png";
import ClientImg30 from "../../assets-new/images/home-client-carousel/client-section-logo-30.png";
import ClientImg31 from "../../assets-new/images/home-client-carousel/client-section-logo-31.png";
import ClientImg32 from "../../assets-new/images/home-client-carousel/client-section-logo-32.png";
import ClientImg33 from "../../assets-new/images/home-client-carousel/client-section-logo-33.png";
import ClientImg34 from "../../assets-new/images/home-client-carousel/client-section-logo-34.png";
// import ClientImg35 from "../../assets-new/images/home-client-carousel/client-section-logo-35.png";
import ClientImg36 from "../../assets-new/images/home-client-carousel/client-section-logo-36.png";
import ClientImg37 from "../../assets-new/images/home-client-carousel/client-section-logo-37.png";
import ClientImg38 from "../../assets-new/images/home-client-carousel/client-section-logo-38.png";
import ClientImg39 from "../../assets-new/images/home-client-carousel/client-section-logo-39.png";
import ClientImg40 from "../../assets-new/images/home-client-carousel/client-section-logo-40.png";
// import ClientImg41 from "../../assets-new/images/home-client-carousel/client-section-logo-41.png";
import ClientImg42 from "../../assets-new/images/home-client-carousel/client-section-logo-42.png";
import ClientImg43 from "../../assets-new/images/home-client-carousel/client-section-logo-43.png";
import ClientImg44 from "../../assets-new/images/home-client-carousel/client-section-logo-44.png";
import ClientImg45 from "../../assets-new/images/home-client-carousel/client-section-logo-45.png";
import ClientImg46 from "../../assets-new/images/home-client-carousel/client-section-logo-46.png";
import ClientImg47 from "../../assets-new/images/home-client-carousel/client-section-logo-47.png";

const Section3 = () => {
  //   const swiperBtn = useSwiper();
  const carouselObj = [
    {
      one: [
        ClientImg1,
        ClientImg2,
        ClientImg3,
        ClientImg4,
        ClientImg5,
        ClientImg6,
        ClientImg7,
        ClientImg8,
        ClientImg9,
        ClientImg10,
        // ClientImg11,
        ClientImg12,
        ClientImg13,
        ClientImg14,
        ClientImg15,
        ClientImg16,
        ClientImg17,
        ClientImg18,
        ClientImg19,
        ClientImg20,
        ClientImg21,
      ],
      two: [
        ClientImg22,
        ClientImg23,
        ClientImg24,
        ClientImg25,
        ClientImg26,
        ClientImg27,
        ClientImg28,
        ClientImg29,
        ClientImg30,
        ClientImg31,
        ClientImg32,
        ClientImg33,
        ClientImg34,
        // ClientImg35,
        ClientImg36,
        ClientImg37,
        ClientImg38,
        ClientImg39,
        ClientImg40,
        // ClientImg41,
        ClientImg42,
        ClientImg43,
      ],
      three: [ClientImg44, ClientImg45, ClientImg46, ClientImg47],
    },
  ];

  const SwiperButtonNext = ({ children }) => {
    const swiper = useSwiper();
    return <button onClick={() => swiper.slideNext()}>{children}</button>;
  };

  const SwiperButtonPrev = ({ children }) => {
    const swiper = useSwiper();
    return <button onClick={() => swiper.slidePrev()}>{children}</button>;
  };
  return (
    <div className="commonSection">
      <div className="container">
        <div className="text-center">
          <h2
            className="sec_title"
            // style={{
            //   marginBottom: 15,
            // }}
          >
            CLIENTS WHO BELIEVE IN US
          </h2>
          <p>We have collaborated with esteemed and reputed brands</p>
        </div>
        <div className="logos-carousel">
          <Swiper
            autoHeight={true}
            loop={true}
            // autoplay={{
            //   delay: 3000,
            //   disableOnInteraction: true,
            // effect={"coverflow"}
            // grabCursor={true}
            // }}
            spaceBetween={30}
            //   effect={"fade"}
            modules={[Autoplay, EffectFade, EffectCoverflow]}
          >
            <div
              className="logo-swiper-bottom-buttons left-btn"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SwiperButtonPrev>
                <div className="btn-left">
                  <GrPrevious />
                </div>
              </SwiperButtonPrev>
            </div>
            <SwiperSlide>
              <div className="logos-carousel-container">
                {carouselObj.map((data, ind) => {
                  return (
                    <React.Fragment key={ind}>
                      <div className="logo-carousel-content">
                        {data.one.map((img) => (
                          <div className="dv-img" key={img}>
                            <img src={img} alt="" />
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="logos-carousel-container">
                {carouselObj.map((data, ind) => {
                  return (
                    <React.Fragment key={ind}>
                      <div className="logo-carousel-content">
                        {data.two.map((img) => (
                          <div className="dv-img" key={img}>
                            <img src={img} alt="" />
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="logos-carousel-container">
                {carouselObj.map((data, ind) => {
                  return (
                    <React.Fragment key={ind}>
                      <div className="logo-carousel-content">
                        {data.three.map((img) => (
                          <div className="dv-img" key={img}>
                            <img src={img} alt="" />
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </SwiperSlide>
            <div className="logo-swiper-bottom-buttons right-btn">
              <SwiperButtonNext>
                <div className="btn-right">
                  <GrNext />
                </div>
              </SwiperButtonNext>
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Section3;

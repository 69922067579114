import React, { useEffect, useState } from "react";
import "./styles/styles.scss";
// import ourVideoProcess from "../../assets-new/images/animation_banner_v2.png";
import { useLocation } from "react-router-dom";
import icon1 from "../../assets-new/images/why-hire-ov/icon-1.svg";
import icon2 from "../../assets-new/images/why-hire-ov/icon-2.svg";
import icon3 from "../../assets-new/images/why-hire-ov/icon-3.svg";
import icon4 from "../../assets-new/images/why-hire-ov/icon-4.svg";
import icon5 from "../../assets-new/images/why-hire-ov/icon-5.svg";
import icon6 from "../../assets-new/images/why-hire-ov/icon-6.svg";
import ScrollSpy from "react-ui-scrollspy";
import ourWorkImg from "../../assets-new/images/our-work/img.png";
// import TheyTrustUs from "./TheyTrustUs";
// import FaqSection from "./FaqSection";
// import OvInfo from "./OvInfo";
import LpFooter from "./LpFooter";
import { AppUseContext } from "../AppContext";
import VideoPopup from "../VideoPopup";
import { FaPlayCircle } from "react-icons/fa";
// import TopForm from "./TopForm";
// import Sideform from "./SideForm";
import MetaData from "../MetaData";
import TestSideForm from "./TestSideForm";
import Img1 from "../../assets-new/images/icons/creative-videos/black/img-1.svg";
import Img2 from "../../assets-new/images/icons/creative-videos/black/img-2.svg";
import Img3 from "../../assets-new/images/icons/creative-videos/black/img-3.svg";
import Img4 from "../../assets-new/images/icons/creative-videos/black/img-4.svg";
import Img5 from "../../assets-new/images/icons/creative-videos/black/img-5.svg";
import Img6 from "../../assets-new/images/icons/creative-videos/black/img-6.svg";
import ImgWhite1 from "../../assets-new/images/icons/creative-videos/white/img-1.svg";
import ImgWhite2 from "../../assets-new/images/icons/creative-videos/white/img-2.svg";
import ImgWhite3 from "../../assets-new/images/icons/creative-videos/white/img-3.svg";
import ImgWhite4 from "../../assets-new/images/icons/creative-videos/white/img-4.svg";
import ImgWhite5 from "../../assets-new/images/icons/creative-videos/white/img-5.svg";
import ImgWhite6 from "../../assets-new/images/icons/creative-videos/white/img-6.svg";
// import desktopLogo from "../../assets-new/images/landing-page-img/desktop-logo-img.jpg";
// import mobileLogo1 from "../../assets-new/images/landing-page-img/mobile_logo-v1.jpg";
// import mobileLogo2 from "../../assets-new/images/landing-page-img/mobile_logo_2.webp";
import industriesImg1 from "../../assets-new/images/industries-catered/img/img-1.png";
import industriesImg2 from "../../assets-new/images/industries-catered/img/img-2.png";
import industriesImg3 from "../../assets-new/images/industries-catered/img/img-3.png";
import industriesImg4 from "../../assets-new/images/industries-catered/img/img-4.png";
import industriesImg5 from "../../assets-new/images/industries-catered/img/img-5.png";
import industriesImg6 from "../../assets-new/images/industries-catered/img/img-6.png";
import Banner1 from "../../assets-new/images/landing-page/banner/banner-1.jpg";
// import Banner2 from "../../assets-new/images/landing-page/banner/banner-2.jpg";
import { Swiper as SwiperComponent } from "swiper/react";
import { SwiperSlide, useSwiper } from "swiper/react";
import {
  Autoplay,
  EffectCoverflow,
  EffectFade,
  // Navigation,
} from "swiper/modules";
import { GrNext, GrPrevious } from "react-icons/gr";
import clientImg1 from "../../assets-new/images/client/ecommerce-lp/img-1.jpg";
import clientImg2 from "../../assets-new/images/client/ecommerce-lp/img-2.jpg";
import clientImg3 from "../../assets-new/images/client/ecommerce-lp/img-3.jpg";
import clientImg4 from "../../assets-new/images/client/ecommerce-lp/img-4.jpg";
import mobileClientImg1 from "../../assets-new/images/client/ecommerce-lp/mobile/img-1.jpg";
import mobileClientImg2 from "../../assets-new/images/client/ecommerce-lp/mobile/img-2.jpg";
import mobileClientImg3 from "../../assets-new/images/client/ecommerce-lp/mobile/img-3.jpg";
import mobileClientImg4 from "../../assets-new/images/client/ecommerce-lp/mobile/img-4.jpg";

const EcommercePhotographyLandingPage = () => {
  const { setIsLandingPageVisible, landingFormId, setIsEcommerceLandingPage } =
    AppUseContext();
  const { toggleVideoPopup } = AppUseContext();

  const pathname = useLocation();

  // const [locationName, setLocationName] = useState("");

  const [landingPageTitle, setLandingPageTitle] = useState("")

  // const navigate = useNavigate();

  useEffect(() => {
    // eslint-disable-next-line
    setIsLandingPageVisible(true);
    setIsEcommerceLandingPage(true)
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "46497548",
          // added condition below because if condition is not kept it will give an error
          formId: landingFormId,
          target: "#hubspotForm",
        });
      }
    });
  }, [pathname, landingFormId]);

  const [landingPageDes, setLandingPageDes] = useState("");
  // const [landingPageTitle, setLandingPageTitle] = useState("")

  useEffect(() => {
    if (pathname.pathname === "/ecommerce-bangalore") {
      setLandingPageDes(
        "Professional eCommerce product photoshoot and videography services in Bangalore to enhance your store with high-quality images and videos that drive sales."
      );
      setLandingPageTitle("Ecommerce Product Photoshoot | Ecommerce Videography Service")
    } else if (pathname.pathname === "/ecommerce-mumbai") {
      setLandingPageDes(
        "Professional eCommerce product photoshoot and videography services in Mumbai to enhance your store with high-quality images and videos that drive sales."
      );
      setLandingPageTitle("e commerce photoshoot")
    }

    // setIsEcommerceLandingPage(true);
    // eslint-disable-next-line
  }, [pathname]);

  const SwiperButtonNext = ({ children }) => {
    const swiper = useSwiper();
    return <button onClick={() => swiper.slideNext()}>{children}</button>;
  };

  const SwiperButtonPrev = ({ children }) => {
    const swiper = useSwiper();
    return <button onClick={() => swiper.slidePrev()}>{children}</button>;
  };

  const clientMobileCheck = window.matchMedia("(max-width: 500px)").matches;

  return (
    <>
      <MetaData
        pageTitle={landingPageTitle}
        metaDdescription={landingPageDes}
      />
      {/* side form below */}
      <TestSideForm hubspotFormId={landingFormId} />

      <ScrollSpy>
        <header className="header">
          <section className="top-navbar">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-7">
                  <div className="left-topbar d-flex align-items-center w-100 h-100 d-none d-lg-block">
                    <p>
                      Professional yet Affordable Ecommerce Photography Studio
                      in Mumbai | Bangalore
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-5 col-sm-12 align-items-center">
                  <div
                    className="right-topbar d-flex align-items-center justify-content-end w-100 h-100"
                    style={{
                      justifyContent: "flex-end",
                    }}
                  >
                    <ul className="d-flex align-items-center h-100">
                      <li>
                        <a
                          href="mailto:sales@orangevideos.in"
                          className="hvr-icon-pop"
                        >
                          sales@orangevideos.in
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!--begin navbar-fixed-top --> */}
          {/* <nav className="navbar navbar-default navbar-fixed-top">
          <div className="container">
            <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand" href="#">
            <img src="images/logo.png" />
            </a>
            <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
                >
                <span className="navbar-toggler-icon">
                  <i className="fas fa-bars"></i>
                  </span>
                  </button>
                  </nav>
                  </div>
                </nav> */}
        </header>

        <section
          className="home-section landing-home-page"
          id="home"
          style={{
            backgroundImage: `url(${Banner1})`,
          }}
        >
          <div className="home-section-overlay"></div>

          {/* <!--begin container --> */}
          <div className="container">
            {/* <!--begin row --> */}
            <div className="row">
              {/* <!--begin col-md-5--> */}
              <div className="col-md-7 header-contain">
                <h1>ORANGE VIDEOS</h1>
                <h3 className="mb-2">Your Ecommerce Photography Studio :</h3>
                <p className="banner_para">
                  Still photography | Lifestyle images | 360 Degree video
                </p>
                {/* <!--<div className="singlefolio" style="width: 250px!important;">--> */}
                {/* <!--<input value="Watch Showreel" className="home-btn" type="submit" style="width:100%!important;">--> */}
                {/* <!--<a className="home-btn plus-btn">Watch Showreel</a>--> */}
                {/* <!--<a className="video_popup video_popup_custom " href="https://www.youtube.com/watch?v=JGV05ck7U0g" style="top:0%!important;width:100%!important;height:53px!important;"><input value="Watch Showreel" className="home-btn" type="submit" style="width:100%!important;"></a>--> */}
                <a
                  className="show-btn mt-4  video_popup"
                  href="https://www.youtube.com/watch?v=JGV05ck7U0g"
                  onClick={(e) => {
                    toggleVideoPopup({
                      videoSrc:
                        "https://www.youtube.com/embed/JGV05ck7U0g?si=CeF2FuVJbZ8s4yvc",
                      param: e,
                    });
                  }}
                >
                  <span>Watch our showreel </span>
                  {/* <i className="button-effect fas fa-play-circle"></i> */}
                  <FaPlayCircle className="button-effect" />
                </a>
                {/* <!--</div>--> */}
              </div>

              <div className="col-md-5 text-center form-media">
                {/* <!--begin register-form-wrapper--> */}
                <div className="register-form-wrapper">
                  <h3 className="mb-2">
                    Need a professional photoshoot for Ecommerce?{" "}
                  </h3>

                  <p className="mb-4">Get an estimate within 24 hours.</p>

                  {/* <!--begin form--> */}
                  <div>
                    {/* <!--begin success message --> */}
                    <p
                      className="register_success_box"
                      style={{ display: "none" }}
                    >
                      Just drop a message. We will get back within 24 hours.
                    </p>
                    {/* <!--end success message --> */}

                    {/* <!--begin register form --> */}

                    <div id="hubspotForm"></div>
                    {/* <!--end register form --> */}
                  </div>
                  {/* <!--end form--> */}
                </div>
                {/* <!--end register-form-wrapper--> */}
              </div>
              {/* <!--end col-md-5--> */}
            </div>
            {/* <!--end row --> */}
          </div>
          {/* <!--end container --> */}
        </section>

        <section className="countdown-section small-paddings">
          {/* <!--begin container --> */}
          <div className="container">
            {/* <!--begin row --> */}
            <div className="row">
              {/* <!--begin col-md-12--> */}
              <div className="col-md-12 text-center">
                <div className="row d-flex justify-content-center">
                  <div className="col-md-2 countdown-num">
                    <h3>300+</h3>
                    <p>Photoshoots Done</p>
                  </div>
                  <div className="col-md-2 countdown-num">
                    <h3>250+</h3>
                    <p>Happy Clients</p>
                  </div>
                  <div className="col-md-2 countdown-num">
                    <h3>12+</h3>
                    <p>Years Experience</p>
                  </div>
                  <div className="col-md-2 countdown-num">
                    <h3>25</h3>
                    <p>Creative Minds</p>
                  </div>
                </div>
              </div>
              {/* <!--end col-md-12--> */}
            </div>
            {/* <!--end row --> */}
          </div>
          {/* <!--end container --> */}
        </section>

        {/* <section
          className="commonSection section-white featured"
          id="corporate_video"
        >
          <div className="container">
            <div className="row d-flex justify-content-center">
              <h2 className="sec_title">Videos that taste different!</h2>
              <div className="col-lg-5 col-sm-12  col-md-5">
                <div className="features_content">
                  <p className="sec_desc orange-color">
                    How do we make our videos?
                  </p>
                  <div className="">
                    <p className="sec_desc">
                      Discover our creative and unique video production process
                    </p>

                    <p className="sec_desc">
                      If you’re thinking about starting a video project and are
                      wondering “what should I expect?” - Here is a detailed
                      overview of the explainer video animation process that
                      we’ve created to give you a better understanding of how
                      this thing works.
                    </p>

                    <p className="sec_desc">
                      Animated videos allow you to transmit your company’s
                      message with ease and simplicity, even if your product or
                      service is super complex to understand at first sight.
                      With animation, the story isn’t restricted by the limits
                      of reality like in a live-action production – you can let
                      your imagination fly and find the best way to attract and
                      convert viewers!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 col-md-6 noPaddingRight">
                <div className="features_img lp-right-overview">
                  <img src={ourVideoProcess} alt="" className="img-fluid" />
                  <div className="lp-overview-right-popup-btn">
                    <span
                      onClick={(e) => {
                        toggleVideoPopup({
                          videoSrc:
                            "https://www.youtube.com/embed/jlobpCWVsUc?si=H6fS8AMFB5Yex-9v",
                          param: e,
                        });
                      }}
                    >
                      <FaPlay />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="commonSection gray-bg" id="services">
          <div className="container creative-videos-on-creative-videos">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h4 className="sub_title red_color">
                  Orange Videos : Your Ecommerce Photography Studio
                </h4>
                <h2 className="sec_title">
                  Professional yet affordable photography service with quick
                  turnaround.
                </h2>
                <p className="sec_desc color_aaa">
                  We understand your requirements like no one else.
                </p>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div
                className="col-lg-4 col-sm-12 col-md-3"
                style={{
                  marginBottom: 20,
                }}
              >
                <span className="icon_box_1 text-center">
                  <div className="flipper">
                    <div className="front">
                      <img alt="ov-img" src={Img1} />
                      <h3>Product photography</h3>
                    </div>
                    <div className="back">
                      <img alt="ov-img" src={ImgWhite1} />
                      <h3>Product photography</h3>
                    </div>
                  </div>
                </span>
              </div>
              <div
                className="col-lg-4 col-sm-12 col-md-3"
                style={{
                  marginBottom: 20,
                }}
              >
                <span
                  to="/professional-corporate-film-video-production-agency-mumbai"
                  className="icon_box_1 text-center"
                >
                  <div className="flipper">
                    <div className="front">
                      <img alt="ov-img" src={Img2} />
                      <h3>Product photography with model</h3>
                    </div>
                    <div className="back">
                      <img alt="ov-img" src={ImgWhite2} />
                      <h3>Product photography with model</h3>
                    </div>
                  </div>
                </span>
              </div>
              <div
                className="col-lg-4 col-sm-12 col-md-3"
                style={{
                  marginBottom: 20,
                }}
              >
                <span
                  to="/product-video-makers-mumbai"
                  className="icon_box_1 text-center"
                >
                  <div className="flipper">
                    <div className="front">
                      <img alt="ov-img" src={Img3} />
                      <h3>Lifestyle images</h3>
                    </div>
                    <div className="back">
                      <img alt="ov-img" src={ImgWhite3} />
                      <h3>Lifestyle images</h3>
                    </div>
                  </div>
                </span>
              </div>
              <div
                className="col-lg-4 col-sm-12 col-md-3"
                style={{
                  marginBottom: 20,
                }}
              >
                <span
                  to="/aerial-videography-photography"
                  className="icon_box_1 text-center"
                >
                  <div className="flipper">
                    <div className="front">
                      <img alt="ov-img" src={Img4} />
                      <h3>Top view / flat lay shots</h3>
                    </div>
                    <div className="back">
                      <img alt="ov-img" src={ImgWhite4} />
                      <h3>Top view / flat lay shots</h3>
                    </div>
                  </div>
                </span>
              </div>
              {/* </div>
          <div className="row d-flex"> */}
              <div
                className="col-lg-4 col-sm-12 col-md-3"
                style={{
                  marginBottom: 20,
                }}
              >
                <span
                  to="/2d-3d-animation-video-production-agency-mumbai"
                  className="icon_box_1 text-center"
                >
                  <div className="flipper">
                    <div className="front">
                      <img alt="ov-img" src={Img5} />
                      <h3>360 degrees video</h3>
                    </div>
                    <div className="back">
                      <img alt="ov-img" src={ImgWhite5} />
                      <h3>360 degrees video</h3>
                    </div>
                  </div>
                </span>
              </div>
              <div
                className="col-lg-4 col-sm-12 col-md-3"
                style={{
                  marginBottom: 20,
                }}
              >
                <span
                  to="/explainer-video-production-company-mumbai"
                  className="icon_box_1 text-center"
                >
                  <div className="flipper">
                    <div className="front">
                      <img alt="ov-img" src={Img6} />
                      <h3>Stop motion videos for your products</h3>
                    </div>
                    <div className="back">
                      <img alt="ov-img" src={ImgWhite6} />
                      <h3>Stop motion videos for your products</h3>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section-white landing-page-hire-ov commonSection"
          id="why-hire-us"
        >
          {/* <!--begin container --> */}
          <div className="container">
            {/* <!--begin row --> */}
            <div className="row">
              {/* <!--begin col-md-12 --> */}
              <div className="col-md-12 text-center margin-bottom-30">
                <h4 className="sub_title red_color">many reasons</h4>
                <h2 className="sec_title">Why Hire Orange Videos?</h2>

                <p className="sec_desc">
                  6 compelling reasons to partner with us
                </p>
              </div>
              {/* <!--end col-md-12 --> */}
            </div>
            {/* <!--end row --> */}
          </div>
          {/* <!--end container --> */}

          {/* <!--begin services-wrapper --> */}
          <div className="services-wrapper">
            {/* <!--begin container --> */}
            <div className="container">
              {/* <!--begin row --> */}
              <div className="row why-hire-ov-container">
                {/* <!--begin col-md-4 --> */}
                <div className="col-md-4 col-lg-4 col-sm-12">
                  <div
                    className="main-services wow fadeIn"
                    data-wow-delay="0.25s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.25s",
                      animationName: "fadeIn",
                    }}
                  >
                    {/* <!--<i className="fas fa-rocket"></i>--> */}
                    <img src={icon1} className="hire_icon" alt="" />

                    <h4>Well equipped In house studio</h4>

                    <p className="sec_desc">
                      Makes things easy for our clients and us: Multiple Cameras
                      & lenses | Required lighting set up | Green screen | Props
                      / art material | Ghost mannequins.
                    </p>
                  </div>
                </div>
                {/* <!--end col-md-4 --> */}

                {/* <!--begin col-md-4 --> */}
                <div className="col-md-4 col-lg-4 col-sm-12">
                  <div
                    className="main-services wow fadeIn"
                    data-wow-delay="0.50s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.5s",
                      animationName: "fadeIn",
                    }}
                  >
                    {/* <!--<i className="fas fa-chart-line"></i>--> */}
                    <img src={icon2} className="hire_icon" alt="" />

                    <h4>Talented & experienced team</h4>

                    <p className="sec_desc">
                      We have a team of industry wise experts, who bring out the
                      best , be it any product.
                    </p>
                  </div>
                </div>
                {/* <!--end col-md-4 --> */}

                {/* <!--begin col-md-4 --> */}
                <div className="col-md-4 col-lg-4 col-sm-12">
                  <div
                    className="main-services wow fadeIn"
                    data-wow-delay="0.75s"
                  >
                    {/* <!--<i className="fas fa-chart-pie"></i>--> */}
                    <img src={icon3} className="hire_icon" alt="" />

                    <h4>In house editors</h4>

                    <p className="sec_desc">
                      Our editors understand the time commitment and work
                      relentlessly to meet the deadlines.
                    </p>
                  </div>
                </div>
                {/* <!--end col-md-4 --> */}

                {/* <!--begin col-md-4 --> */}
                <div className="col-md-4 col-lg-4 col-sm-12">
                  <div className="main-services wow fadeIn" data-wow-delay="1s">
                    {/* <!--<i className="fas fa-anchor"></i>--> */}
                    <img src={icon4} className="hire_icon" alt="" />

                    <h4>Quicker Turnaround time</h4>

                    <p className="sec_desc">
                      Quicker deliveries are guaranteed as we have set processes
                      in terms of pre-production, logistics, production and
                      editing which makes us an efficient team.
                    </p>
                  </div>
                </div>
                {/* <!--end col-md-4 --> */}

                {/* <!--begin col-md-4 --> */}
                <div className="col-md-4 col-lg-4 col-sm-12">
                  <div
                    className="main-services wow fadeIn"
                    data-wow-delay="1.25s"
                  >
                    {/* <!--<i className="fas fa-thumbs-up"></i>--> */}
                    <img src={icon5} className="hire_icon" alt="" />

                    <h4>AFFORDABLE PRICE</h4>

                    <p className="sec_desc">
                      We have a team of industry wise experts, who bring out the
                      best , be it any product.
                    </p>
                  </div>
                </div>
                {/* <!--end col-md-4 --> */}

                {/* <!--begin col-md-4 --> */}
                <div className="col-md-4 col-lg-4 col-sm-12">
                  <div
                    className="main-services wow fadeIn"
                    data-wow-delay="1.5s"
                  >
                    {/* <!--<i className="fab fa-rocketchat"></i>--> */}
                    <img src={icon6} className="hire_icon" alt="" />

                    <h4>Merchandise care</h4>

                    <p className="sec_desc">
                      We ensure all the merchandised is handled with care and
                      returned to the client in good condition.
                    </p>
                  </div>
                </div>
                {/* <!--end col-md-4 --> */}
              </div>
              {/* <!--end row --> */}
            </div>
            {/* <!--end container --> */}
          </div>
          {/* <!--end services-wrapper --> */}
        </section>

        <section className="commonSection porfolio gray-bg" id="our-work">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                {/* <h4 className="sub_title red_color">OUR PORTFOLIO</h4> */}
                <h2 className="sec_title">Our work</h2>
                {/* <p className="sec_desc">
                  In this video portfolio, you'll find some of the best
                  corporate videos we've created over the past couple of years.
                  <br />
                  Our secret recipe? Passion, a brand-oriented focus, and
                  out-of-the-box thinking!{" "}
                  <Link to="/contact">
                    <span
                      style={{
                        borderBottom: "1px solid #ff950d",
                        color: "#ff950d",
                      }}
                    >
                      Give us a try
                    </span>
                  </Link>{" "}
                  and see for yourself!
                </p> */}
              </div>
              <div>
                <img src={ourWorkImg} width="100%" alt="" />
              </div>
            </div>
            {/* <div className="row">
              <div className="container">
                <div className="col-md-12 text-center">
                  <Link className="common_btn" to="/portfolio">
                    <span>view more</span>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </section>

        {/* <TheyTrustUs /> */}
        <section
          className="commonSection section-white section-bottom-border"
          id="our-clients"
        >
          {/* <!--begin container --> */}
          <div className="container">
            {/* <!--begin row --> */}
            <div className="row">
              {/* <!--begin col-md-12--> */}
              <div className="col-md-12 text-center padding-bottom-10">
                <h4 className="sub_title red_color">Discover our clients</h4>
                <h2 className="sec_title">Our clients</h2>

                {/* <p className="sec_desc">Trusted by the best</p> */}
              </div>
              {/* <!--end col-md-12 --> */}
            </div>
            {/* <!--end row --> */}

            <div className="commonSection">
              <div className="container">
                <div className="text-center">
                  <h2
                    className="sec_title"
                  // style={{
                  //   marginBottom: 15,
                  // }}
                  >
                    CLIENTS WHO BELIEVE IN US
                  </h2>
                  <p>We have collaborated with esteemed and reputed brands</p>
                </div>
                <div className="logos-carousel">
                  <SwiperComponent
                    autoHeight={true}
                    loop={true}
                    // autoplay={{
                    //   delay: 3000,
                    //   disableOnInteraction: true,
                    // effect={"coverflow"}
                    // grabCursor={true}
                    // }}
                    spaceBetween={30}
                    //   effect={"fade"}
                    modules={[Autoplay, EffectFade, EffectCoverflow]}
                  >
                    <div
                      className="logo-swiper-bottom-buttons left-btn"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <SwiperButtonPrev>
                        <div className="btn-left">
                          <GrPrevious />
                        </div>
                      </SwiperButtonPrev>
                    </div>
                    <SwiperSlide>
                      <div className="logos-carousel-container">
                        <img
                          src={
                            clientMobileCheck ? mobileClientImg1 : clientImg1
                          }
                          alt=""
                          width="100%"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logos-carousel-container">
                        <img
                          src={
                            clientMobileCheck ? mobileClientImg2 : clientImg2
                          }
                          alt=""
                          width="100%"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logos-carousel-container">
                        <img
                          src={
                            clientMobileCheck ? mobileClientImg3 : clientImg3
                          }
                          alt=""
                          width="100%"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logos-carousel-container">
                        <img
                          src={
                            clientMobileCheck ? mobileClientImg4 : clientImg4
                          }
                          alt=""
                          width="100%"
                        />
                      </div>
                    </SwiperSlide>
                    <div className="logo-swiper-bottom-buttons right-btn">
                      <SwiperButtonNext>
                        <div className="btn-right">
                          <GrNext />
                        </div>
                      </SwiperButtonNext>
                    </div>
                  </SwiperComponent>
                </div>
              </div>
            </div>

            {/* <!--begin row --> */}
            {/* <div className="row hidden-mobile">
              <div className="col-md-12 client-img">
                <img src={desktopLogo} loading="lazy" alt="" width="100%" />
              </div>
            </div> */}

            {/* <!--end col-md-6 --> */}

            {/* <div className="row display-mobile">
              <div className="col-xs-12 client-img">
                <img src={mobileLogo1} loading="lazy" alt="" width="100%" />
              </div>
            </div> */}
            {/* <!--end row --> */}

            {/* <div className="row display-mobile">
              <div className="col-xs-12 client-img">
                <img src={mobileLogo2} loading="lazy" alt="" width="100%" />
              </div>
            </div> */}
            {/* <!--end row --> */}
          </div>
          {/* <!--end container --> */}
        </section>

        <section className="commonSection porfolio gray-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2
                  className="sec_title"
                  style={{
                    marginBottom: "54px",
                  }}
                >
                  Industries we have catered to
                </h2>
              </div>
            </div>
            <div className="row industries-created-container">
              <div className="col-md-4 col-lg-4 col-sm-12 industries-created-content">
                <img src={industriesImg1} alt="" />
                <div className="text-center content">Clothing / apparel</div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 industries-created-content">
                <img src={industriesImg2} alt="" />
                <div className="text-center content">Bags</div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 industries-created-content">
                <img src={industriesImg3} alt="" />
                <div className="text-center content">Mats / carpets</div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 industries-created-content">
                <img src={industriesImg4} alt="" />
                <div className="text-center content">
                  Personal care products
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 industries-created-content">
                <img src={industriesImg5} alt="" />
                <div className="text-center content">Jewellery</div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 industries-created-content">
                <img src={industriesImg6} alt="" />
                <div className="text-center content">Food & beverages</div>
              </div>
            </div>
          </div>
        </section>

        {/* <FaqSection /> */}

        {/* <OvInfo /> */}
        <section className="ov-info small-paddings" id="ov_sec">
          {/* <!--begin container --> */}
          <div className="container">
            {/* <!--begin row --> */}
            <div className="row">
              {/* <!--begin col-md-12--> */}
              <div className="col-md-12 text-center">
                <h2 className="black-text sec_title">Orange Videos</h2>

                <p className="black-text">
                  Your One Stop Shop for Ecommerce Photography & Videography
                  requirements. We help brands get the best out of their
                  merchandise using the power of cameras, lights and our
                  experienced crew of photographers, videographers and editors.
                </p>

                <a href="#home" className="countdown-cta scrool">
                  Want an estimate for your requirement, or have more questions?
                  <span>Get in Touch.</span>
                </a>
              </div>
              {/* <!--end col-md-12--> */}
            </div>
            {/* <!--end row --> */}
          </div>
          {/* <!--end container --> */}
        </section>

        <LpFooter />
      </ScrollSpy>

      <VideoPopup />
    </>
  );
};

export default EcommercePhotographyLandingPage;

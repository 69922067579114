import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const AppCreateContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [popupVideo, setPopupVideo] = useState("");
  const location = useLocation();
  const [isOnHomepage, setIsOnHomepage] = useState(true);
  const [pageErr, setPageErr] = useState(false);
  const [isLandingPageVisible, setIsLandingPageVisible] = useState(false);
  const [landingFormId, setIsLandingFormId] = useState("");
  const [isEcommerceLandingPage, setIsEcommerceLandingPage] = useState(false);

  // const recaptchaSiteKey = "6LcZ7_8pAAAAACaGIPd8UO3BkWsKJ-v3UnD7Sjyj";
  const recaptchaSiteKey = "6LcQ3mYpAAAAABI09J1mhM3mVdC--p_WbeCX4Oyp";

  useEffect(() => {
    setIsOnHomepage(location.pathname === "/test" ? true : false);
    // console.log(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (
      location.pathname === "/animation-explainer-video-bangalore" ||
      location.pathname === "/animation-explainer-video-bangalore-test"
    ) {
      setIsLandingFormId(process.env.REACT_APP_ANIMATION_BANGALORE_ENV);
      // console.log(process.env.REACT_APP_ANIMATION_BANGALORE_ENV);
    } else if (
      location.pathname === "/animation-explainer-video-mumbai" ||
      location.pathname === "/animation-explainer-video-mumbai-test"
    ) {
      setIsLandingFormId(process.env.REACT_APP_ANIMATION_MUMBAI_ENV);
    } else if (
      location.pathname === "/animation-explainer-video-pune" ||
      location.pathname === "/animation-explainer-video-pune-test"
    ) {
      setIsLandingFormId(process.env.REACT_APP_ANIMATION_PUNE_ENV);
    } else if (
      location.pathname === "/corporate-video-production-mumbai" ||
      location.pathname === "/corporate-video-production-mumbai-test"
    ) {
      setIsLandingFormId(process.env.REACT_APP_CORPORATE_MUMBAI_ENV);
    } else if (
      location.pathname === "/corporate-video-production-bangalore" ||
      location.pathname === "/corporate-video-production-bangalore-test"
    ) {
      setIsLandingFormId(process.env.REACT_APP_CORPORATE_BANGALORE_ENV);
    } else if (
      location.pathname === "/corporate-video-production-pune" ||
      location.pathname === "/corporate-video-production-pune-test"
    ) {
      setIsLandingFormId(process.env.REACT_APP_CORPORATE_PUNE_ENV);
    } else if (location.pathname === "/ecommerce-mumbai") {
      setIsLandingFormId(process.env.REACT_APP_ECOMMERCE_MUMBAI_ENV);
    } else if (location.pathname === "/ecommerce-bangalore") {
      setIsLandingFormId(process.env.REACT_APP_ECOMMERCE_BANGALORE_ENV);
    } else if (location.pathname === "/digital-ads-mumbai") {
      setIsLandingFormId(
        process.env.REACT_APP_DIGITAL_ADS_MUMBAI_FORM_BANNER_ENV
      );
    } else if (location.pathname === "/digital-ads-bangalore") {
      setIsLandingFormId(
        process.env.REACT_APP_DIGITAL_ADS_BANGALORE_FORM_BANNER_ENV
      );
    }
  }, [location]);

  const toggleVideoPopup = ({ videoSrc, param }) => {
    param.preventDefault();
    setIsPopupActive(!isPopupActive);
    setPopupVideo(videoSrc);
  };
  return (
    <AppCreateContext.Provider
      value={{
        isOnHomepage,
        setIsOnHomepage,
        isPopupActive,
        setIsPopupActive,
        setPopupVideo,
        popupVideo,
        toggleVideoPopup,
        pageErr,
        setPageErr,
        recaptchaSiteKey,
        setIsLandingPageVisible,
        isLandingPageVisible,
        landingFormId,
        setIsLandingFormId,
        isEcommerceLandingPage,
        setIsEcommerceLandingPage,
      }}
    >
      {children}
    </AppCreateContext.Provider>
  );
};

export function AppUseContext() {
  return useContext(AppCreateContext);
}
